import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import Util from '../../../util';

const MultiSelect = ({
  value, multiSelectPlaceholder, data, setData, filterLabel,
}) => {
  let ref = useRef();
  const [selectOption, setSelectOption] = useState('');
  const [showFilterInputs, setShowFilterInputs] = useState(false);

  /**
   * Handler for when the user CLICKS on the dropdown
   * @param {object} e - The event
   * @returns {void}
   */
  const handleDropDownOnClick = (e) => {
    const dropdown = e.currentTarget || e.target.closest('.slds-combobox');

    if (dropdown) {
      dropdown.setAttribute('aria-expanded', true);
      dropdown.classList.add('slds-is-open');
      if (ref) ref.focus();
    }
  };

  /**
   * Handler for when the user UN-FOCUS (BLURS) the dropdown
   * @param {object} e - The event
   * @returns {void}
   */
  const handleDropDownOnBlur = (e) => {
    const dropdown = e.target.closest('.slds-combobox');

    // If we click on a child, don't do anything so it can call its handler(s)
    if (dropdown && !dropdown.contains(e.relatedTarget)) {
      dropdown.setAttribute('aria-expanded', false);
      dropdown.classList.remove('slds-is-open');
      setSelectOption('');
    }
  };

  /**
   * Add or delete an option to the selected value
   * @param {object} option - DOM element of the option
   * @returns {void} Will call the onOptionClick function from prop with new value and the selected value
   */
  const handleAddOrDeleteOption = (option) => {
    const currentState = option.getAttribute('aria-selected');
    const selectedValue = option.dataset.value;
    option.setAttribute('aria-expanded', !currentState);
    option.classList.toggle('slds-is-selected');

    let newValue;

    if (value && Array.isArray(value)) {
      // Remove the value - If it already exists
      if (value.includes(selectedValue)) newValue = value.filter(v => v !== selectedValue);

      // Add the value - If it does not already exist
      else newValue = [...value, selectedValue];
    } else newValue = selectedValue;

    // if the value is empty replace it with an empty array
    if (newValue === '') newValue = [];

    // set newValue in data as an array
    setData(Array.isArray(newValue) ? newValue : [newValue], selectedValue);
  };

  /**
   * Handler for when the user CLICKS on the cross of a pill
   * @param {object} e - The event
   * @returns {void}
   */
  const handleClickOnDeleteValue = (e) => {
    const deletedValue = e.currentTarget.dataset.label;

    const option = Array.from( // Transform NodeList -> Array
      e.target
        .closest('.multi-select') // Find container
        .querySelectorAll('.slds-listbox__option'), // Find all options
    )
      // Find the option we want
      .find(v => v.getAttribute('aria-selected') === 'true' && v.dataset.value === deletedValue);

    // if option if found, then set updated data
    if (option) handleAddOrDeleteOption(option);
  };

  /**
   * Handler for when the user CLICKS on an option of the dropdown
   * @param {object} e - The event
   * @returns {void}
   */
  const handleDropDownOptionOnClick = (e) => {
    const option = e.currentTarget || e.target.closest('.slds-listbox__option');

    // update the data
    if (option) handleAddOrDeleteOption(option);
  };

  // it's regex for searching value in the search field
  const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // returns filtered data based on searching field
  const filteredData = data.filter(v => v.name && String(v.name).match(
    new RegExp(escapeRegExp(selectOption), 'i'),
  ));

  /**
   * Renders HTML Object for list of selected items (called listbox)
   * @returns {Object} The HTML for the listbox group
   */
  const renderListBox = () => (
    <div className="slds-listbox_selection-group">
      <ul
        className="slds-listbox slds-listbox_horizontal"
        role="listbox"
        aria-label="Selected Options:"
        aria-orientation="horizontal"
      >
        {value?.length ?
          value.map(val => (
            <li className="slds-listbox-item " role="presentation" key={val}>
              {filterLabel ?
                (
                  <Label className="selected-filter-label">
                    <div className="filter-data-label" title={Util.returnPropertyNameById(data, val)}>
                      {Util.returnPropertyNameById(data, val)}
                    </div>
                    <span
                      className="slds-icon_container slds-pill__remove"
                      title="Remove"
                      data-label={val}
                      onClick={handleClickOnDeleteValue}
                    >
                      <Icon name="delete" />
                    </span>
                  </Label>
                ) :
                (
                  <span className="slds-pill" role="option" tabIndex="0" aria-selected="true">
                    <span className="slds-pill__label" title={Util.returnPropertyNameById(data, val)}>
                      {Util.returnPropertyNameById(data, val)}
                    </span>
                    <span
                      className="slds-icon_container slds-pill__remove"
                      title="Remove"
                      data-label={val}
                      onClick={handleClickOnDeleteValue}
                    >
                      <svg className="slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                      </svg>
                    </span>
                  </span>
                )}
            </li>
          )) :
          null}
      </ul>
    </div>
  );

  // class names for dropdown trigger component
  const dropdownTriggerClassName = classNames(
    'slds-select_container',
    // eslint-disable-next-line quote-props
    { 'hide': !showFilterInputs && filterLabel },
  );

  /**
   * Renders class name for listbox component
   * @param {object} org - organisation
   * @returns {string} class name
   */
  const listboxClassName = org => classNames(
    'slds-media slds-listbox__option slds-listbox__option_plain slds-media_small',
    { 'slds-is-selected': value?.find(o => o === org._id) },
  );

  return (
    <>
      <div className="multi-select slds-form-element" id="multi-select">
        {filterLabel && (
          <div className="filter-container-with-listbox">
            {renderListBox()}
            <div className="multi-select-container-with-filter-label">
              <Button
                onClick={() => setShowFilterInputs(!showFilterInputs)}
                icon
                labelPosition="left"
                className="filter-button"
              >
                <Icon name="filter" />
                Filter
              </Button>
            </div>
          </div>
        )}

        <div className={dropdownTriggerClassName}>
          <div
            onClick={handleDropDownOnClick}
            onBlur={handleDropDownOnBlur}
            onMouseDown={e => e.preventDefault()}
            className="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click"
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-controls=""
            role="combobox"
          >
            <div className="slds-combobox__form-element">
              <input
                ref={(input) => { ref = input; }}
                type="text"
                className="slds-input slds-combobox__input slds-combobox__input-value slds-truncate"
                placeholder={multiSelectPlaceholder}
                autoComplete="off"
                aria-controls=""
                onChange={e => setSelectOption(e.target.value)}
                value={selectOption}
              />
            </div>

            <div
              id="listbox-id-7"
              className="slds-dropdown slds-dropdown_length-5 slds-dropdown_fluid"
              role="listbox"
            >
              {filteredData?.length ?
                filteredData.map(org => (
                  <ul className="slds-listbox slds-listbox_vertical" role="presentation" key={org._id}>
                    <li role="presentation" className="slds-listbox__item">
                      <div
                        onClick={handleDropDownOptionOnClick}
                        id="option1"
                        className={listboxClassName(org)}
                        aria-selected={!!value?.find(o => o === org._id)}
                        role="option"
                        tabIndex="0"
                        data-value={org._id}
                      >
                        <span className="slds-media__figure slds-listbox__option-icon">
                          {value?.find(o => o === org._id) ?
                            (
                              <span className="slds-icon_container slds-icon-utility-check slds-current-color">
                                <svg className="slds-icon slds-icon_x-small" aria-hidden="true">
                                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#check" />
                                </svg>
                              </span>
                            ) :
                            ''}
                        </span>
                        <span className="slds-media__body">
                          <span className="slds-truncate" title={org.name}>{org.name}</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                )) :
                null}
            </div>
          </div>
        </div>
        {!filterLabel && renderListBox()}
      </div>
    </>
  );
};

MultiSelect.propTypes = {
  /**
   * Array of objects used to render the options.
   */
  data: PropTypes.instanceOf(Array).isRequired,
  /**
   * Functions that sets selected value in data
   */
  setData: PropTypes.func.isRequired,
  /**
   * Currently selected value(s). Should be an array of strings or a string.
   * Not required because it can be empty (no value selected).
   */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.string,
  ]),
  /**
   * Placeholder for input component in combobox
   */
  multiSelectPlaceholder: PropTypes.string.isRequired,
  /**
   * Defines whether a filter label is to be used
   */
  filterLabel: PropTypes.bool,
};

export default MultiSelect;
