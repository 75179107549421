import React, {
  useState, useEffect,
} from 'react';

import UsersAPI from '../../../../api/users';
import InstallDemoDataAPI from '../../../../api/installDemoData';

import './style.scss';
import Constants from '../../../../constants/constants';
import MultiSelect from '../../atoms/MultiSelect';
import OrganisationsAPI from '../../../../api/organisations';
import Spinner from '../../atoms/Spinner';

const DemoDataPackage = () => {
  const LABEL__INSTALL = 'Install Demo Data Package';
  const LABEL__INSTALLING = 'Installing... Please wait';

  const [engageOrgsData, setEngageOrgsData] = useState([]);
  const [segmentOrgsData, setSegmentOrgsData] = useState([]);
  const [product, setProduct] = useState(Constants.DESELECT_SEGMENT);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [dateFormat, setDateFormat] = useState();
  const [submitButtonLabel, setSubmitButtonLabel] = useState(LABEL__INSTALL);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [installTrainingDEs, setInstallTrainingDEs] = useState(false);
  const [organisationsData, setOrganisationsData] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchEngageData = async () => {
    const orgs = await OrganisationsAPI.getEngageOrganisations();

    setEngageOrgsData(orgs);
  };

  const fetchSegmentData = async () => {
    const orgs = await OrganisationsAPI.getSegmentActiveOrganisations();

    setSegmentOrgsData(orgs);
    setOrganisationsData(orgs);
  };

  const fetchOrgData = async () => {
    await Promise.allSettled([fetchSegmentData(), fetchEngageData()]);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchOrgData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Create options for Users dropdown
   * @returns {Array[]} Array of <options>
   */
  const renderUsersDropdownOptions = () => {
    const options = [];
    for (let k = 0; k < users.length; k += 1) {
      options.push(
        <option value={users[k]._id} id={users[k]._id}>
          {users[k].name}
          {' '}
          - business unit:
          {' '}
          {users[k].loggedInBusinessUnitId}
        </option>,
      );
    }
    return options;
  };

  /**
   * Handle changing Product dropdown
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeProduct = (e) => {
    setProduct(e.target.value);

    if (e.target.value === Constants.DESELECT_ENGAGE) setOrganisationsData(engageOrgsData);
    else setOrganisationsData(segmentOrgsData);
  };

  /**
   * Handle changing organisations dropdown
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeOrganisation = async (e) => {
    if (product === Constants.DESELECT_SEGMENT) {
      const u = await UsersAPI.getUsersForOrg(e.target.value);
      setUsers(u.users);
      // Set first user as default
      if (u.users.length) {
        setUserId(u.users[0]._id);
      }
    }

    if (product === Constants.DESELECT_ENGAGE) {
      const businessUnitsData = engageOrgsData.find(engageOrg => engageOrg._id === e.target.value)
        ?.businessUnits
        ?.map(businessUnit => ({
          _id: businessUnit.businessUnitId.toString(),
          name: businessUnit.businessUnitId,
        }));

      setBusinessUnits(businessUnitsData);
      setSelectedBusinessUnits([]);
    }
  };

  /**
   * Handle changing users dropdown
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeUser = (e) => {
    setUserId(e.target.value);
  };

  /**
   * Handle changing date format dropdown
   * @param {Object} e - Change event
   * @returns {void}
   */
  const handleChangeDateformat = (e) => {
    setDateFormat(e.target.value);
  };

  /**
   * Handle submitting the form
   * @param {Object} e - Submit event
   * @returns {void}
   */
  const submit = async (e) => {
    // Disable submit button
    setSubmitButtonLabel(LABEL__INSTALLING);
    setSubmitButtonDisabled(true);

    e.preventDefault();

    let dateFormatValue;
    if (dateFormat === Constants.DATE_FORMAT__DD_MM_YYYY) {
      dateFormatValue = '2';
    } else if (dateFormat === Constants.DATE_FORMAT__MM_DD_YYYY) {
      dateFormatValue = '1';
    } else {
      dateFormatValue = 'autoSelect';
    }

    try {
      const res = await InstallDemoDataAPI.install(null, {
        dateFormat: dateFormatValue,
        installTrainingDEs,
        userId,
        product,
        businessUnits: selectedBusinessUnits,
      });

      // Make details readable
      const resultText = res.details.join('\n');

      // eslint-disable-next-line no-alert
      alert('Package Installed Successfully!\n' + resultText);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Package Installation Failed: ' + error.response.data.error);
    }

    // Restore submit button
    setSubmitButtonLabel(LABEL__INSTALL);
    setSubmitButtonDisabled(false);
  };

  if (isLoading) {
    return (
      <Spinner size="large" assistiveText="Loading..." />
    );
  }

  return (
    <>
      <form id="form" onSubmit={submit}>

        <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="product">Product</label>
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select className="slds-select" id="product" onChange={e => handleChangeProduct(e)}>
                <option>{Constants.DESELECT_SEGMENT}</option>
                <option>{Constants.DESELECT_ENGAGE}</option>
              </select>
            </div>
          </div>
        </div>

        <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="organisation">Organisation</label>
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select className="slds-select" id="organisation" onChange={e => handleChangeOrganisation(e)}>
                <option selected disabled>Select Organisation</option>
                {organisationsData.map(org => (<option key={org._id} value={org._id} id={org._id}>{org.name}</option>))}
              </select>
            </div>
          </div>
        </div>

        {product === Constants.DESELECT_ENGAGE ?
          (
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="multi-select">
                Business Units
                <MultiSelect
                  multiSelectPlaceholder="Select Business Units to install DEMO Data to"
                  data={businessUnits}
                  setData={setSelectedBusinessUnits}
                  value={selectedBusinessUnits}
                />
              </label>
            </div>
          ) :
          (
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="user">Users</label>
              <div className="slds-form-element__control">
                <div className="slds-select_container">
                  <select className="slds-select" id="user" onChange={e => handleChangeUser(e)}>
                    {renderUsersDropdownOptions()}
                  </select>
                </div>
              </div>
            </div>
          )}

        <div className="slds-form-element">
          <label className="slds-form-element__label" htmlFor="dateFormat">Date Format</label>
          <div className="slds-form-element__control">
            <div className="slds-select_container">
              <select className="slds-select" id="dateFormat" onChange={e => handleChangeDateformat(e)}>
                <option>Auto Select</option>
                <option>{Constants.DATE_FORMAT__MM_DD_YYYY}</option>
                <option>{Constants.DATE_FORMAT__DD_MM_YYYY}</option>
              </select>
            </div>
          </div>
        </div>

        {product === Constants.DESELECT_SEGMENT && (
          <div className="slds-form-element">
            <label className="slds-form-element__label" htmlFor="installTrainingDEs">
              Install Training Data Extensions
              <div className="slds-form-element__control">
                <div className="slds-checkbox">
                  <input
                    onChange={() => setInstallTrainingDEs(!installTrainingDEs)}
                    type="checkbox"
                    name="installTrainingDEs"
                    id="installTrainingDEs"
                    checked={installTrainingDEs}
                  />
                  <label className="slds-checkbox__label" htmlFor="installTrainingDEs">
                    <span className="slds-checkbox_faux" />
                    <span className="slds-form-element__label">
                      Yes
                    </span>
                  </label>
                </div>
              </div>
            </label>
          </div>
        )}

        <br />
        <br />
        <button type="submit" className="slds-button slds-button_brand" id="install" disabled={submitButtonDisabled}>
          {submitButtonLabel}
        </button>

      </form>
    </>
  );
};

export default DemoDataPackage;
