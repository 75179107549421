import axios from 'axios';
import Constants from '../constants/constants';

const apiUrl = process.env.REACT_APP_API_URL;

const StatsAPI = {
  /**
   * Get stats for all organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getOrganisationsStats: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/stats/organisations`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get stats for customers
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} filterCriteria - Criteria to filter the results on
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getCustomerOrganisationsStats: async (cancelToken, filterCriteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/stats/customers`,
      filterCriteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get data to fill the engagement dashboard only
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} filterCriteria - Criteria to filter the results on
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getCustomerEngagementData: async (cancelToken, filterCriteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/engagement/customers`,
      filterCriteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get data to fill the essentials dashboard only
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getFreeCustomersData: async (cancelToken) => {
    const res = await axios.post(
      `${apiUrl}/admin/engagement/free`,
      {},
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get tickets without organisation assigned
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getUnmappedSegmentTickets: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/stats/tickets/segment`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Get stats for one organisation
   * @param {string} orgId - Id of org for which stats need to be retrieved
   * @param {string} product - Product for which stats need to be retrieved
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getOrganisationStats: async (orgId, product, cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/stats/organisation/${product === Constants.DESELECT_SEGMENT ? 'segment' : 'engage'}/${orgId}`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.data;
  },

  /**
   * Get ranked Users for a Organisation based on Health Score
   * @param {string} orgId - Id of org for which users needs to be retrieved
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getOrganisationRankedUsers: async (orgId, cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/stats/rankedUsers/${orgId}`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data.data;
  },

  /**
   * Get chrome extension stats
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `data` property - the retrieved stats - and `success`
   */
  getChromeExtensionStats: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/stats/chrome-extension`, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default StatsAPI;
