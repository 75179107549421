import axios from 'axios';
import Constants from '../constants/constants';

const apiUrl = process.env.REACT_APP_API_URL;

const OrganisationsAPI = {
  /**
   * Retrieves single Segment organisation by id
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} id - Organisation id
   * @returns {object} - Retrieved organisation
   */
  getSegmentOrganisation: async (cancelToken, id) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/segment/${id}`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Retrieves single Engage organisation by id
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} id - Organisation id
   * @returns {object} - Retrieved organisation
   */
  getEngageOrganisation: async (cancelToken, id) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/engage/${id}`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Retrieves organisations based on the product type
   * @param {string} product - The product type ('segment' or 'engage')
   * @param {object} criteria - Criteria to filter the results (only for segment)
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {Promise<object|array>} Retrieved organisations
   * @throws {Error} If invalid product type is provided
   */
  getOrganisations: async (product, criteria, cancelToken) => {
    switch (product) {
      case Constants.DESELECT_SEGMENT:
        return OrganisationsAPI.getSegmentOrganisations(cancelToken, criteria);
      case Constants.DESELECT_ENGAGE:
        return OrganisationsAPI.getEngageOrganisations(cancelToken);
      default:
        throw new Error(`Invalid product type: ${product}. Must be 'segment' or 'engage'.`);
    }
  },

  /**
   * Retrieves all Segment organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @param {object} criteria - Criteria to filter the results on
   * @returns {object} An object with `organisations` property - the retrieved organisations -
   */
  getSegmentOrganisations: async (cancelToken, criteria) => {
    const res = await axios.post(
      `${apiUrl}/admin/orgs/search`,
      criteria,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Retrieves all segment active organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `organisations` property - the retrieved organisations -
   */
  getSegmentActiveOrganisations: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/segment`,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data?.data;
  },

  /**
   * Retrieves all organisations
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {array} Array of orgs and their BUs
   */
  getEngageOrganisations: async (cancelToken) => {
    const res = await axios.get(
      `${apiUrl}/admin/orgs/engage`,
      {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data.data;
  },

  /**
   * Updates an organisation
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the organisation you want to update
   * @param {string} product - Product
   * @param {string} name - Name of the organisation
   * @param {string} hubspotID - Hubspot ID of the organisation
   * @param {string} zendeskID - Zendesk ID of the organisation
   * @param {string} type - Type of the organisation
   * @param {string} token - Token of the organisation
   * @param {string} authenticationBaseUrl - authenticationBaseUrl of the installed package
   * @param {array} businessUnits - Business units of the organisation
   * @param {boolean} isActive - Should this organisation be active
   * @param {string} edition - Edition of the organisation
   * @param {number} licenses - The number of licenses the org possesses
   * @param {boolean} latePayment - Indicates if customer is late on payments
   * @param {boolean} hasServer2ServerInstalledPackage - Indicates if
   * customer has the DESelect Automations package installed
   * @param {string} server2ServerClientId - Client Id of DESelect Automations package
   * @param {string} server2ServerClientSecret - Client Secret of DESelect Automations packages
   * @param {object} features - Features object
   * @return {object} An object with `result` property - always true - and `success`
   */
  updateOrganisation: async (
    cancelToken,
    id,
    product,
    {
      name,
      hubspotID,
      zendeskID,
      type,
      token,
      authenticationBaseUrl,
      businessUnits,
      isActive,
      edition,
      licenses,
      latePayment,
      hasServer2ServerInstalledPackage,
      server2ServerClientId,
      server2ServerClientSecret,
      features,
    },
  ) => {
    const update = {
      name,
      hubspotID,
      zendeskID,
      type,
      token,
      authenticationBaseUrl,
      businessUnits,
      isActive,
      edition,
      latePayment,
      hasServer2ServerInstalledPackage,
      server2ServerClientId,
      server2ServerClientSecret,
      features,
    };

    if (product === Constants.DESELECT_SEGMENT) {
      update.licenses = licenses;
    } else {
      update.engageLicenses = licenses;
    }

    const res = await axios.put(
      `${apiUrl}/admin/orgs/${product === Constants.DESELECT_SEGMENT ? 'segment' : 'engage'}/${id}`, update, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Insert an organisation
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} product - Product
   * @param {string} name - Name of the organisation
   * @param {string} hubspotID - Hubspot ID of the organisation
   * @param {string} zendeskID - Zendesk ID of the organisation
   * @param {string} type - Type of the organisation
   * @param {string} token - Token of the organisation
   * @param {string} authenticationBaseUrl - authenticationBaseUrl of the installed package
   * @param {array} businessUnits - Business units of the organisation
   * @param {boolean} isActive - Should this organisation be active
   * @param {string} edition - Edition of the organisation
   * @param {number} licenses - The number of licenses the org possesses
   * @param {boolean} latePayment - Indicates if customer is late on payments
   * @param {boolean} hasServer2ServerInstalledPackage - Indicates if customer
   * has the DESelect Automations package installed
   * @param {string} server2ServerClientId - Client Id of DESelect Automations package
   * @param {string} server2ServerClientSecret - Client Secret of DESelect Automations packages
   * @return {object} An object with `result` property - always true - and `success`
   */
  insertOrganisation: async (
    cancelToken,
    product,
    {
      name,
      hubspotID,
      zendeskID,
      type,
      token,
      authenticationBaseUrl,
      businessUnits,
      isActive,
      edition,
      licenses,
      latePayment,
      hasServer2ServerInstalledPackage,
      server2ServerClientId,
      server2ServerClientSecret,
    },
  ) => {
    const update = {
      name,
      hubspotID,
      zendeskID,
      type,
      token,
      authenticationBaseUrl,
      businessUnits,
      isActive,
      edition,
      latePayment,
      hasServer2ServerInstalledPackage,
      server2ServerClientId,
      server2ServerClientSecret,
    };

    if (product === Constants.DESELECT_SEGMENT) {
      update.licenses = licenses;
    } else {
      update.engageLicenses = licenses;
    }

    const res = await axios.post(
      `${apiUrl}/admin/orgs/${product === Constants.DESELECT_SEGMENT ? 'segment' : 'engage'}/`,
      update,
      {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },

  /**
   * Set new feature flag
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} feature - name of the feature flag
   * @param {string} defaultValue - default value of the feature flag
   * @param {string} criteria - MongoDB query for identifying organisations to set this for
   * @return {object} An object with `result` property - always true - and `success`
   */
  newFeatureFlag: async (
    cancelToken,
    feature,
    defaultValue,
    criteria,
  ) => {
    const res = await axios.post(
      `${apiUrl}/admin/new-feature`, {
        feature,
        defaultValue,
        criteria,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );
    return res.data;
  },
};

export default OrganisationsAPI;
