import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';

import UserNotificationsAPI from '../../../../api/user-notifications';

import Swal from '../../../../helpers/Swal';

import Filter from './Filter';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

const UserNotificationsDataset = ({ validate }) => {
  const [results, setResults] = useContext(ResultsContext);
  const { userNotificationsData, filteredResults } = results;
  const [resultsList, setResultsList] = useState([]);
  const [state, setState] = useContext(UtilsContext);
  const { showingFilteredResults, filter } = state;
  const [loading, setLoading] = useState(false);

  console.log('state', state);

  // Notification variables
  const [userId, setUserId] = useState('');
  const [notificationId, setNotificationId] = useState('');
  const [isDismissed, setIsDismissed] = useState(false);
  const [organisationQuery, setOrganisationQuery] = useState('');
  const [usersQuery, setUsersQuery] = useState('');

  const filterResultsHandler = async (e, filterBy, searchText) => {
    setLoading(true);
    const filterCriteria = {};

    // If filtering by notification
    if (filterBy === Constants.FILTER_BY__NOTIFICATION_ID) {
      filterCriteria.notificationId = searchText;
    } else if (filterBy === Constants.FILTER_BY__USER_ID) {
      // Filtering by user
      filterCriteria.userId = searchText;
    }

    try {
      // Get filtered results
      const res = await UserNotificationsAPI.getUserNotifications(null, filterCriteria);

      // Set state
      setState({ ...state, showingFilteredResults: true, filter: searchText });

      // Show filtered results
      setResults({ ...results, filteredResults: res.data });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `Something unexpected happened: ${error.message || error}`,
      );
    }
  };

  /**
   * Stops the filtering of results
   * @returns {void}
   */
  const handleStopFiltering = () => {
    // Reset values
    setResultsList(userNotificationsData);
    setState({ ...state, showingFilteredResults: false, filter: null });
    setResults({ ...results, filteredResults: [] });
  };

  // UseEffect for setting results' lists
  useEffect(() => {
    // If filtering...
    if ((filteredResults && filteredResults.length) || showingFilteredResults) {
      setResultsList(filteredResults);
    } else {
      // Show unfiltered data
      setResultsList(userNotificationsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotificationsData, filteredResults]);

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setUserId('');
    setNotificationId('');
    setIsDismissed(false);
    setOrganisationQuery('');
    setUsersQuery('');
  };

  /**
   * Handler called when saving a user notification
   * @returns {void}
   */
  const handleSave = async () => {
    let newData = {};

    switch (state.eventType) {
      case Constants.EVENT__TYPE__NEW:
        try {
          newData = {
            notificationId,
            organisationQuery: JSON.parse(organisationQuery || '{}'),
            usersQuery: JSON.parse(usersQuery || '{}'),
          };
        } catch (error) {
          Util.handleError(error, 'Please use valid JSON for organisation / users query.');
          return;
        }
        break;
      case Constants.EVENT__TYPE__UPDATE:
        newData = {
          notificationId,
          userId,
          isDismissed,
        };
        break;
      default:
        return;
    }

    const validateResult = validate(newData);

    if (validateResult.length > 0) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
    } else {
      try {
        if (state.eventType === Constants.EVENT__TYPE__NEW) {
          const res = await UserNotificationsAPI.createUserNotification(null, newData);

          setResults({
            ...results,
            userNotificationsData: [
              ...userNotificationsData,
              ...res.data,
            ],
          });

          setState({ ...state, isOpen: false, eventType: '' });
          Swal.fireSuccess('Created!', 'The user notification has been created.');
        } else if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          const res = await UserNotificationsAPI.updateUserNotification(null, state.id, newData);
          const newArray = [...userNotificationsData];
          newArray[state.index] = res.data;
          setResults({ ...results, userNotificationsData: newArray });

          setState({ ...state, isOpen: false, eventType: '' });
          Swal.fireSuccess('Updated!', 'The user notification has been updated.');
        }
        cleanFields();
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when canceling a user notification modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when editing a user notification
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Prefilled textbox
    const singleItem = userNotificationsData.filter(e => e._id === id)[0];
    setUserId(singleItem.userId);
    setNotificationId(singleItem.notificationId);
    setIsDismissed(singleItem.isDismissed);
    /**
     * No corresponding properties, this is intended
     * setOrganisationQuery(...);
     * setUsersQuery(...);
     */
  };

  /**
   * Handler called when deleting a user notification
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleDelete = async (event) => {
    event.persist(); // See https://reactjs.org/docs/events.html

    try {
      const res = await Swal.fireWarning({
        icon: 'warning',
        title: 'Are you sure to delete this user notification?',
        text: 'You won\'t be able to revert this.',
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonColor: '#d33',
      });

      if (res.value) {
        const { id } = event.target.dataset;
        const { index } = event.target.dataset;

        await UserNotificationsAPI.deleteUserNotification(null, id);

        // Delete results
        const newArray = [...userNotificationsData];
        newArray.splice(index, 1);
        setResults({ ...results, userNotificationsData: newArray });

        Swal.fireSuccess('Deleted!', 'The user notification has been deleted.');
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Find a User from a user Id
   * @param {string} uId - The user Id
   * @returns {object} The user
   */
  const findUser = uId => state.segmentUsersData ? state.segmentUsersData.find(u => u._id === uId) : '';

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__CSM,
      Constants.USER__PERMISSION__WRITE,
    );

  return (
    <div className="user-notification">
      <Filter
        handleStopFiltering={handleStopFiltering}
        showingFilteredItems={showingFilteredResults}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filter}
      />
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Username">
                Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="User Id">
                User Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Notification Id">
                Notification Id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="is Dismissed">
                Is dismissed
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Actions">
                Actions
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {resultsList && resultsList.length > 0 ?
            resultsList.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="Username">
                  <div className="slds-truncate" title={findUser(el.userId).username}>
                    {findUser(el.userId).username}
                  </div>
                </td>

                <td data-label="User Id">
                  <div className="slds-truncate" title={el.userId}>
                    {el.userId}
                  </div>
                </td>

                <td data-label="Message">
                  <div className="slds-truncate" title={el.notificationId}>
                    {el.notificationId}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate">
                    {el.isDismissed ?
                      (
                        <span className="slds-badge slds-theme_success">
                          True
                        </span>
                      ) :
                        <span className="slds-badge slds-theme_error">False</span>}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.createdAt}>
                    {el.createdAt}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.updatedAt}>
                    {el.updatedAt}
                  </div>
                </td>

                <td data-label="Actions">
                  {userHasEditRights ?
                    (
                      <div className="slds-truncate" title="Delete">
                        <svg
                          className="slds-icon slds-icon-text-error"
                          aria-hidden="true"
                          style={{
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          <use
                            xlinkHref="/assets/icons/action-sprite/svg/symbols.svg#delete"
                            data-id={el._id}
                            data-index={i}
                            onClick={handleDelete}
                            style={{ cursor: 'pointer' }}
                          />
                        </svg>
                      </div>
                    ) :
                    null}
                </td>
              </tr>
            )) :
            (
              <tr>
                <td colSpan="8" className="no-results-found">No results found</td>
              </tr>
            )}
        </tbody>
      </table>
      {/* Dataset Section */}

      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={state.isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              User Notifications Form
            </h2>
          </header>
          {state.eventType === Constants.EVENT__TYPE__NEW && (
            // eslint-disable-next-line max-len
            <div className="slds-grid slds-grid_vertical-align-center slds-p-around_x-small slds-theme_shade slds-theme_alert-texture ">
              <div className="slds-col">
                <span className="slds-icon_container slds-icon-utility-info">
                  <svg className="slds-icon slds-icon-text-default" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info" />
                  </svg>
                </span>
              </div>
              <div className="slds-text-heading_small slds-text-align_center">
                <p>
                  Pressing Save here will create User Notifications for all users
                  matching the Users Query of all organisations matching the
                  Organisation Query.
                </p>
              </div>
            </div>
          )}

          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-notificationId">
                Notification Id
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="form-element-notificationId"
                      value={notificationId}
                      onChange={event => setNotificationId(event.target.value)}
                    >
                      <option value="" defaultValue>
                        Please select
                      </option>
                      {state.notificationsData ?
                        state.notificationsData.map(el => (
                          <option value={el._id} key={el._id}>
                            {`${el.title} (${el._id})`}
                          </option>
                        )) :
                        null}
                    </select>
                  </div>
                </div>
              </label>
            </div>

            {/* Fields available only in NEW mode */}
            {state.eventType === Constants.EVENT__TYPE__NEW && (
              <>
                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-organisation-query">
                    Organisation Query
                    <div className="slds-form-element__control">
                      <textarea
                        id="form-element-organisation-query"
                        className="slds-textarea"
                        // eslint-disable-next-line spellcheck/spell-checker
                        placeholder='{ "_id": "5ed642b7cb26bc4430948e4b" }'
                        rows="5"
                        onChange={event => setOrganisationQuery(event.target.value)}
                        value={organisationQuery}
                      />
                    </div>
                  </label>
                </div>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-users-query">
                    Users Query
                    <div className="slds-form-element__control">
                      <textarea
                        id="form-element-users-query"
                        className="slds-textarea"
                        // eslint-disable-next-line spellcheck/spell-checker
                        placeholder='{ "_id": "5ed642b7cb26bc4430948e4b" }'
                        rows="5"
                        onChange={event => setUsersQuery(event.target.value)}
                        value={usersQuery}
                      />
                    </div>
                  </label>
                </div>
              </>
            )}

            {/* Fields available only in EDIT mode */}
            {state.eventType === Constants.EVENT__TYPE__UPDATE && (
              <>
                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-userId">
                    User Id
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="form-element-userId"
                        placeholder="5ed642b7cb26bc4430948e4b"
                        className="slds-input"
                        onChange={event => setUserId(event.target.value)}
                        value={userId}
                      />
                    </div>
                  </label>
                </div>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="isDismissed">
                    Is dismissed
                    <div className="slds-form-element__control">
                      <div className="slds-checkbox">
                        <input
                          onChange={() => setIsDismissed(!isDismissed)}
                          type="checkbox"
                          name="isDismissed"
                          id="isDismissed"
                          checked={isDismissed}
                        />
                        <label className="slds-checkbox__label" htmlFor="isDismissed">
                          <span className="slds-checkbox_faux" />
                          <span className="slds-form-element__label">
                            {isDismissed ? 'True' : 'False'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </>
            )}
          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              className="slds-button slds-button_brand"
              disabled={!userHasEditRights}
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div
        className={state.isOpen ? 'slds-backdrop slds-backdrop_open' : ''}
      />
      {/* Modal Section */}
    </div>
  );
};

UserNotificationsDataset.propTypes = {
  /**
   * Function to validate the user input
   */
  validate: PropTypes.func.isRequired,
};

export default UserNotificationsDataset;
