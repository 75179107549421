/* eslint-disable spellcheck/spell-checker */
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import { MenuContext } from '../../../MenuContext';
import { ResultsContext } from '../../../ResultsContext';
import { UtilsContext } from '../../../UtilsContext';

import AuthAPI from '../../../api/auth';
import StatsAPI from '../../../api/stats';
import ChangeLogsAPI from '../../../api/changelogs';
import OrganisationsAPI from '../../../api/organisations';
import NotificationsAPI from '../../../api/notifications';
import AlertsAPI from '../../../api/alert-users';
import UserNotificationsAPI from '../../../api/user-notifications';
import AdminUsersAPI from '../../../api/adminusers';
import UsersAPI from '../../../api/users';
import SelectionRunLogsAPI from '../../../api/selectionRunLogs';
import SettingsApi from '../../../api/settings';

import './style.scss';
import Constants from '../../../constants/constants';
import Util from '../../../util';
import Nav from '../../../nav';
import FeatureAdvertsAPI from '../../../api/feature-adverts';
import GuidanceTipsAPI from '../../../api/guidance-tips';

const LeftNav = () => {
  const menuContext = useContext(MenuContext);
  const [menuSelected, setMenuSelected] = menuContext.menuSelected;
  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);

  const history = useHistory();
  /**
   * Handle logout process
   * @param {object} event - A JS Event
   * @returns {void}
   */
  const handleLogout = async (event) => {
    event.preventDefault();

    try {
      await AuthAPI.logout();
    } catch (error) {
      Cookies.remove(Constants.AUTH__COOKIE_NAME);
    }
    history.push(Constants.ROUTE__LOGIN);
  };

  /**
   * Handle click on link
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleLink = async (event) => {
    event.preventDefault();
    const linkName = event.target.dataset.link;
    setMenuSelected(linkName);
    const {
      dashboardData,
      engagementDashboardData,
      essentialsDashboardData,
      adminUsersData,
      segmentUsersData,
      engageUsersData,
      segmentOrganisationsData,
      engageOrganisationsData,
      changeLogsData,
      notificationsData,
      guidanceTips,
      alertsData,
      userNotificationsData,
      selectionRunLogsData,
    } = results;

    try {
      if (linkName === Constants.MENU__NAME__DASHBOARD) {
        // If data has not been obtained before
        if (dashboardData && !dashboardData.length) {
          // Get data
          const res = await StatsAPI.getOrganisationsStats(null);

          // Set data
          setResults({ ...results, dashboardData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD) {
        // If data has not been obtained before
        if (engagementDashboardData && !engagementDashboardData.length) {
          // Get data
          const res = await StatsAPI.getCustomerEngagementData(
            null,
            {
              edition: { $ne: Constants.DESELECT__EDITION__ESSENTIALS },
            },
          );
          const resTickets = await StatsAPI.getUnmappedSegmentTickets(null);

          // Set data
          setResults(
            {
              ...results,
              engagementDashboardData: res.data,
              unmappedTicketsData: resTickets.data.unmappedTickets,
            },
          );
        }
      } else if (linkName === Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD) {
        // If data has not been obtained before
        if (essentialsDashboardData && !essentialsDashboardData.length) {
          // Get data
          const res = await StatsAPI.getFreeCustomersData(
            null,
          );

          const resTickets = await StatsAPI.getUnmappedSegmentTickets(null);

          // Set data
          setResults(
            {
              ...results,
              essentialsDashboardData: res.data,
              unmappedTicketsData: resTickets.data.unmappedTickets,
            },
          );
        }
      } else if (linkName === Constants.MENU__NAME__SFMC_QUICK_SEARCH) {
        // If data has not been obtained before
        if (dashboardData && !dashboardData.length) {
          // Get data
          const res = await StatsAPI.getChromeExtensionStats(null);
          // Set data
          setResults({ ...results, dashboardData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__CHANGELOG) {
        // If data has not been obtained before
        if (changeLogsData && !changeLogsData.length) {
          // Get data
          const res = await ChangeLogsAPI.getChangeLogs(null);
          // Set data
          setResults({ ...results, changeLogsData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__SELECTION_RUN_LOG) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (selectionRunLogsData && !selectionRunLogsData.length) {
          // Get data
          const res = await SelectionRunLogsAPI.getSelectionRunLogs(null);
          // Set data
          valuesToUpdate.selectionRunLogsData = res.data;
        }

        // If data has not been obtained before
        if (segmentOrganisationsData && !segmentOrganisationsData.length) {
          // Get data
          const orgs = await OrganisationsAPI.getSegmentOrganisations(null);
          // Set data
          valuesToUpdate.segmentOrganisationsData = orgs.data;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) setResults({ ...results, ...valuesToUpdate });
      } else if (linkName === Constants.MENU__NAME__SEGMENT_ORGANISATIONS) {
        // If data has not been obtained before
        if (segmentOrganisationsData && !segmentOrganisationsData.length) {
          // Get data
          const res = await OrganisationsAPI.getSegmentOrganisations(null);
          const featureAdverts = await FeatureAdvertsAPI.getAdverts(null);
          // Set data
          setResults({ ...results, segmentOrganisationsData: res.data, featureAdvertsData: featureAdverts.data });
        }
      } else if (linkName === Constants.MENU__NAME__ENGAGE_ORGANISATIONS) {
        // If data has not been obtained before
        if (engageOrganisationsData && !engageOrganisationsData.length) {
          // Get data
          const orgs = await OrganisationsAPI.getEngageOrganisations(null);
          // Set data
          setResults({ ...results, engageOrganisationsData: orgs });
        }
      } else if (linkName === Constants.MENU__NAME__ADMIN_USERS) {
        const valuesToUpdate = {};

        // If data has not been obtained before
        if (adminUsersData && !adminUsersData.length) {
          // Get data
          const res = await AdminUsersAPI.getAdminUsers(null);
          // Set data
          valuesToUpdate.adminUsersData = res.data;

          setResults({ ...results, valuesToUpdate });
        }
      } else if (linkName === Constants.MENU__NAME__SEGMENT_USERS) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (segmentUsersData && !segmentUsersData.length) {
          // Get data
          const res = await UsersAPI.getUsers(Constants.DESELECT_SEGMENT, null);
          // Set data
          valuesToUpdate.segmentUsersData = res.users;
        }
        // If data has not been obtained before
        if (segmentOrganisationsData && !segmentOrganisationsData.length) {
          // Get data
          const orgs = await OrganisationsAPI.getSegmentOrganisations(null);
          // Set data
          valuesToUpdate.segmentOrganisationsData = orgs.data;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) setResults({ ...results, ...valuesToUpdate });
      } else if (linkName === Constants.MENU__NAME__ENGAGE_USERS) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (engageUsersData && !engageUsersData.length) {
          // Get data
          const res = await UsersAPI.getUsers(Constants.DESELECT_ENGAGE, null);
          // Set data
          valuesToUpdate.engageUsersData = res.users;
        }
        // If data has not been obtained before
        if (engageOrganisationsData && !engageOrganisationsData.length) {
          // Get data
          const orgs = await OrganisationsAPI.getEngageOrganisations(null);
          // Set data
          valuesToUpdate.engageOrganisationsData = orgs;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) setResults({ ...results, ...valuesToUpdate });
      } else if (linkName === Constants.MENU__NAME__NOTIFICATION) {
        // If data has not been obtained before
        if (notificationsData && !notificationsData.length) {
          // Get data
          const res = await NotificationsAPI.getNotifications(null);
          // Set data
          setResults({ ...results, notificationsData: res.data });
          setState({
            ...state,
            notificationsData: res.data,
          });
        }
      } else if (linkName === Constants.MENU__NAME__ALERTS) {
        // If data has not been obtained before
        if (!alertsData?.length) {
          // Get data
          const res = await AlertsAPI.getAlerts(null);
          // Set data
          setResults({ ...results, alertsData: res.data });
          setState({
            ...state,
            alertsData: res.data,
          });
        }
      } else if (linkName === Constants.MENU__NAME__USER_NOTIFICATION) {
        const valuesToUpdate = {};
        // If data has not been obtained before
        if (userNotificationsData && !userNotificationsData.length) {
          // Get data
          const res = await UserNotificationsAPI.getUserNotifications(null);
          valuesToUpdate.userNotificationsData = res.data;
        }
        // If data has not been obtained before
        if (notificationsData && !notificationsData.length) {
          // Get data
          const notifications = await NotificationsAPI.getNotifications(null);
          valuesToUpdate.notificationsData = notifications.data;
        }
        // If data has not been obtained before
        if (segmentUsersData && !segmentUsersData.length) {
          // Get data
          const users = await UsersAPI.getUsers(Constants.DESELECT_SEGMENT, null);
          valuesToUpdate.segmentUsersData = users.users;
        }
        // If valuesToUpdate is not empty
        if (Object.keys(valuesToUpdate).length) {
          setState({
            ...state,
            ...valuesToUpdate,
          });
          setResults({ ...results, ...valuesToUpdate });
        }
      } else if (linkName === Constants.MENU__NAME__TIPS) {
        if (guidanceTips && !guidanceTips.length) {
          // Get data
          const retrievedTips = await GuidanceTipsAPI.getGuidanceTips(null);
          setResults(({ ...results, guidanceTips: retrievedTips }));
        }
      } else if (linkName === Constants.MENU__NAME__DEMO_DATA_PACKAGE) {
        // If data has not been obtained before
        if (segmentOrganisationsData && !segmentOrganisationsData.length) {
          // Get data
          const res = await OrganisationsAPI.getSegmentOrganisations(null);
          // Set data
          setResults({ ...results, segmentOrganisationsData: res.data });
        }
      } else if (linkName === Constants.MENU__NAME__SFMC_USER_SETUP) {
        console.log('MENU__NAME__SFMC_USER_SETUP');
      } else if (linkName === Constants.MENU__NAME__SFMC_OFFBOARDING) {
        console.log('MENU__NAME__SFMC_OFFBOARDING');
      } else if (linkName === Constants.MENU__NAME__SETTINGS) {
        const valuesToUpdate = {};
        // Get data
        const res = await SettingsApi.get(null);
        valuesToUpdate.settings = res.data;

        setState({
          ...state,
          ...valuesToUpdate,
        });
        setResults({ ...results, settings: res.data });
      } else if (linkName === Constants.MENU__NAME__LOGOUT) {
        history.push('/login');
      } else {
        // eslint-disable-next-line no-console
        console.log('unexpected menu link:' + linkName);
      }
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Check if user has permission to see a link from Nav
   * @param {String} link - Link to check
   * @returns {Boolean} True if user has permission to view the link
   */
  const userHasPermissionToSeeLink = link => link.permissions.includes(true);

  /**
   * Renders a Navigation Item
   * @param {string} name - The name of the item
   * @param {string} link - Where to redirect to
   * @param {function} onClick - Handler for the onClick event, default to handleLink
   * @returns {object} HTML for the Navigation Item
   */
  const renderNavLink = (name, link, onClick = handleLink) => (
    <li
      className={`slds-nav-vertical__item${menuSelected === name ? ' slds-is-active' : ''}`}
      key={name}
    >
      <Link
        className="slds-nav-vertical__action"
        to={link}
        onClick={onClick}
        data-link={name}
      >
        {name}
      </Link>
    </li>
  );

  return (
    <div className="nav-vertical_container" style={{ width: '320px' }}>
      <nav className="slds-nav-vertical slds-nav-vertical_shade" aria-label="Sub page">
        <div className="slds-nav-vertical__section">
          <h2 id="nav-item-segment" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            DESelect Segment
          </h2>
          <ul aria-describedby="nav-item-segment">
            {Nav.getPermissionsSegment().map((n) => {
              if (userHasPermissionToSeeLink(n)) {
                return renderNavLink(n.name, n.path);
              }
              return false;
            })}
          </ul>
        </div>

        <div className="slds-nav-vertical__section">
          <h2 id="nav-item-engage" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            DESelect Engage
          </h2>
          <ul aria-describedby="nav-item-engage">
            {Nav.getPermissionsEngage().map((n) => {
              if (userHasPermissionToSeeLink(n)) {
                return renderNavLink(n.name, n.path);
              }
              return false;
            })}
          </ul>
        </div>

        {
          Util.userHasPermission(Constants.USER__PERMISSION__REPORTS) ||
          Util.userHasPermission(Constants.USER__PERMISSION__CSM, Constants.USER__PERMISSION__WRITE) ?
            (

              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-sfmc-quicksearch"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  DESelect Search
                </h2>
                {Util.userHasPermission(Constants.USER__PERMISSION__REPORTS) ?
                  (
                    <ul aria-describedby="nav-item-sfmc-quicksearch">
                      {renderNavLink(Constants.MENU__NAME__SFMC_QUICK_SEARCH, Constants.MENU__LINK__SFMC_QUICK_SEARCH)}
                    </ul>
                  ) :
                  null}

                {Util.userHasPermission(Constants.USER__PERMISSION__MARKETING) ?
                  (
                    <ul aria-describedby="nav-item-sfmc-quicksearch">
                      {renderNavLink(Constants.MENU__NAME__SFMC_QUICK_SEARCH_HOME_MESSAGE,
                        Constants.MENU__NAME__SFMC_QUICK_SEARCH_HOME_MESSAGE)}
                    </ul>
                  ) :
                  null}
              </div>
            ) :
            null
}

        <div>
          {(Util.userHasPermission(Constants.USER__PERMISSION__CSM, Constants.USER__PERMISSION__WRITE) ||
           Util.userHasPermission(Constants.USER__PERMISSION__SETUP, Constants.USER__PERMISSION__WRITE)) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-sfmc-demodatapackage"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  SFMC Onboarding
                </h2>
                <ul aria-describedby="nav-item-sfmc-demodatapackage">
                  {renderNavLink(Constants.MENU__NAME__DEMO_DATA_PACKAGE, Constants.MENU__LINK__DEMO_DATA_PACKAGE)}
                </ul>
                {Util.userHasPermission(Constants.USER__PERMISSION__SETUP, Constants.USER__PERMISSION__WRITE) ?
                  (
                    <ul aria-describedby="nav-item-sfmc-user-onboarding-offboarding">
                      {renderNavLink(Constants.MENU__NAME__SFMC_USER_SETUP, Constants.MENU__LINK__SFMC_USER_SETUP)}
                      {renderNavLink(Constants.MENU__NAME__SFMC_OFFBOARDING, Constants.MENU__LINK__SFMC_OFFBOARDING)}
                    </ul>
                  ) :
                  null}
              </div>
            ) :
            null}

          {Util.userHasPermission(Constants.USER__PERMISSION__DEVELOPER, Constants.USER__PERMISSION__WRITE) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-sfmc-demodatapackage"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Testing
                </h2>
                <ul aria-describedby="nav-item-set-test-token">
                  {renderNavLink(Constants.MENU__NAME__SET_TEST_TOKEN, Constants.MENU__NAME__SET_TEST_TOKEN)}
                </ul>
              </div>
            ) :
            null}
        </div>

        {Util.userHasPermission(Constants.USER__PERMISSION__CSM, Constants.USER__PERMISSION__WRITE) ?
          (
            <div className="slds-nav-vertical__section">
              <h2
                id="nav-item-certifications-app"
                className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
              >
                Customer Success
              </h2>
              <ul aria-describedby="nav-item-asana-tasks">
                {renderNavLink(Constants.MENU__NAME__ASANA_TASKS,
                  Constants.MENU__NAME__ASANA_TASKS)}
              </ul>
            </div>
          ) :
          null}

        {/* <div className="slds-nav-vertical__section">
          <h2 id="nav-item-connect" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            DESelect Connect
          </h2>
          <ul aria-describedby="nav-item-integrations">
            {renderNavLink(Constants.MENU__NAME__INTEGRATIONS, Constants.MENU__NAME__INTEGRATIONS)}
          </ul>
        </div> */}

        {
          (Util.userHasPermission(Constants.USER__PERMISSION__FINANCE, Constants.USER__PERMISSION__WRITE)) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-finance"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Finance
                </h2>
                <ul aria-describedby="nav-item-create-invoice">
                  {renderNavLink(Constants.MENU__NAME__CREATE_INVOICE, Constants.MENU__NAME__CREATE_INVOICE)}
                </ul>
                <ul aria-describedby="nav-item-stamp-deal">
                  {renderNavLink(Constants.MENU__NAME__STAMP_DEAL, Constants.MENU__NAME__STAMP_DEAL)}
                </ul>
                <ul aria-describedby="nav-item-currency-rates">
                  {renderNavLink(Constants.MENU__NAME__CURRENCY_RATES, Constants.MENU__NAME__CURRENCY_RATES)}
                </ul>
              </div>
            ) :
            null
        }

        {
          (Util.userHasPermission(Constants.USER__PERMISSION__SETUP)) ?
            (
              <div className="slds-nav-vertical__section">
                <h2
                  id="nav-item-admin-app"
                  className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium"
                >
                  Admin App
                </h2>
                <ul aria-describedby="nav-item-admin-users">
                  {renderNavLink(Constants.MENU__NAME__ADMIN_USERS, '/admin-users')}
                </ul>
              </div>
            ) :
            null
        }

        <div className="slds-nav-vertical__section">
          <h2 id="nav-item-account" className="slds-nav-vertical__title slds-text-heading_large slds-m-bottom_medium">
            Account
          </h2>
          <ul aria-describedby="nav-item-account">
            {renderNavLink(Constants.MENU__NAME__LOGOUT, Constants.ROUTE__LOGOUT, handleLogout)}
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default LeftNav;
