const Constants = {
  // DESelect
  DESELECT__EDITION__ADVANCED: 'advanced',
  DESELECT__EDITION__PLUS: 'plus',
  DESELECT__EDITION__ENABLE: 'enable',
  DESELECT__EDITION__ESSENTIALS: 'essentials',
  DESELECT__ORGANISATION_TYPE__CUSTOMER: 'customer',
  DESELECT__ORGANISATION_TYPE__PARTNER: 'partner',
  DESELECT__ORGANISATION_TYPE__INTERNAL: 'internal',

  // Base URLS
  BASE_URL__DESELECT_SEGMENT: 'https://api.deselect.io',
  BASE_URL__DESELECT_CONNECT: 'https://connect-api.deselect.io',

  // Event
  EVENT__TYPE__NEW: 'new',
  EVENT__TYPE__UPDATE: 'update',
  EVENT__TYPE__UPDATE_MANY: 'update many',
  EVENT__TYPE__READ_ONLY: 'read only',
  EVENT__TYPE__FEATURES_PICKLIST: 'features picklist',

  // destination for transfer list modal
  TRANSFER_LIST__DESTINATION__QUESTIONS: 'questions',

  // Authentication
  AUTH__COOKIE_NAME: '__session_token',

  // Products
  DESELECT_SEGMENT: 'DESelect Segment',
  DESELECT_ENGAGE: 'DESelect Engage',

  // Menu
  MENU__NAME__DASHBOARD: 'Dashboard',
  MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD: 'Segment Customers Dashboard',
  MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD: 'Segment Free Customers Dashboard',
  MENU__NAME__ADOPTION_DASHBOARD: 'Adoption Dashboard',
  MENU__NAME__CHANGELOG: 'Changelogs',
  MENU__NAME__SELECTION_RUN_LOG: 'Selection Run Log',
  MENU__NAME__SEGMENT_ORGANISATIONS: 'Segment/Search Organisations',
  MENU__NAME__ENGAGE_ORGANISATIONS: 'Engage Organisations',
  MENU__NAME__ADMIN_USERS: 'Admin Users',
  MENU__NAME__SEGMENT_USERS: 'Segment/Search Users',
  MENU__NAME__ENGAGE_USERS: 'Engage Users',
  MENU__NAME__FEATURE: 'Features',
  MENU__NAME__NOTIFICATION: 'Notifications',
  MENU__NAME__USER_NOTIFICATION: 'User Notifications',
  MENU__NAME__TIPS: 'Tips',
  MENU__NAME__DEMO_DATA_PACKAGE: 'Install Demo Data Package',
  MENU__NAME__SFMC_USER_SETUP: 'SFMC Onboarding',
  MENU__NAME__SFMC_OFFBOARDING: 'SFMC Offboarding',
  MENU__NAME__SET_TEST_TOKEN: 'Set Test Token',
  MENU__NAME__RELATIONS: 'Relations',
  MENU__NAME__INTEGRATIONS: 'Integrations',
  MENU__NAME__ASANA_TASKS: 'Asana Tasks',
  MENU__NAME__LOGOUT: 'Logout',
  MENU__NAME__SFMC_QUICK_SEARCH: 'DESelect Search Dashboard',
  MENU__NAME__SFMC_QUICK_SEARCH_HOME_MESSAGE: 'Search Home Message',
  MENU__NAME__SETTINGS: 'Settings',
  MENU__NAME__ALERTS: 'Alerts',

  MENU__LINK__ADOPTION_DASHBOARD: 'adoption-dashboard',
  MENU__LINK__DEMO_DATA_PACKAGE: 'demo-data-package',
  MENU__LINK__SFMC_USER_SETUP: 'sfmc-user-setup',
  MENU__LINK__SFMC_OFFBOARDING: 'sfmc-user-offboarding',
  MENU__LINK__SFMC_QUICK_SEARCH: 'sfmc-quick-search-dashboard',

  // Finance
  MENU__NAME__CREATE_INVOICE: 'Create Invoice',
  MENU__NAME__STAMP_DEAL: 'Stamp Deal',
  MENU__NAME__CURRENCY_RATES: 'Currency Rates',

  // Demo Data Package
  DATE_FORMAT__DD_MM_YYYY: 'DD/MM/YYYY',
  DATE_FORMAT__MM_DD_YYYY: 'MM/DD/YYYY',

  // Route
  ROUTE__LOGIN: '/login',
  ROUTE__LOGOUT: '/logout',
  ROUTE__OVERVIEW: '/',

  // Roles
  ROLE__READ_ONLY: 'readonly',
  ROLE__ADMIN: 'admin',
  ROLE__SUPER_ADMIN: 'superAdmin',
  ROLE__FINANCE: 'finance',

  // Permission default settings
  PERMISSION_DEFAULT__AE: 'ae',
  PERMISSION_DEFAULT__CSM: 'csm',
  PERMISSION_DEFAULT__PRODUCT: 'product',
  PERMISSION_DEFAULT__MARKETING: 'marketing',
  PERMISSION_DEFAULT__ENGINEER: 'engineer',
  PERMISSION_DEFAULT__FINANCE: 'finance',

  // Permissions
  USER__PERMISSION__ACADEMY: 'academy',
  USER__PERMISSION__FINANCE: 'finance',
  USER__PERMISSION__DEVELOPER: 'developer',
  USER__PERMISSION__CSM: 'csm',
  USER__PERMISSION__PRODUCT: 'product',
  USER__PERMISSION__MARKETING: 'marketing',
  USER__PERMISSION__REPORTS: 'reports',
  USER__PERMISSION__SETUP: 'setup',

  USER__PERMISSION__READ: 'read',
  USER__PERMISSION__WRITE: 'write',

  // Question difficulty levels
  DIFFICULTY_LEVEL__EASY: 'easy',
  DIFFICULTY_LEVEL__MEDIUM: 'medium',
  DIFFICULTY_LEVEL__HARD: 'hard',
  DIFFICULTY_LEVEL__UNCLEAR: 'unclear',

  // Filter by
  FILTER_BY__ORG: 'org',
  FILTER_BY__SELECTION: 'selection',
  FILTER_BY__DOCUMENT_ID: 'documentId',
  FILTER_BY__USER_ID: 'userId',
  FILTER_BY__NOTIFICATION_ID: 'notificationId',
  FILTER_BY__ENTERPRISE_BUSINESS_UNIT_ID: 'enterpriseBusinessUnitId',
  FILTER_BY__NAME: 'name',
  FILTER_BY__ORGANISATION_ID: 'organisationId',
  FILTER_BY__ANSWERED: 'answered',
  FILTER_BY__DATE: 'date',
  FILTER_BY__TOPIC_ID: 'topicId',
  FILTER_BY__CUSTOMER_SUCCESS_MANAGER: 'CSM',
  FILTER_BY__ACCOUNT_EXECUTIVE: 'AE',

  // search by
  SEARCH_BY__MODULE_NAME: 'moduleName',
  SEARCH_BY__TRAINING_NAME: 'trainingName',
  SEARCH_BY__TOPIC_NAME: 'topicName',
  SEARCH_BY__QUESTION: 'question',
  SEARCH_BY__ORGANISATION_NAME: 'organisationName',

  HUBSPOT__COMPANY__BASE_URL: 'https://app.hubspot.com/contacts/5443771/company',

  GENERATED_EXAM__CSV__COLUMNS: [
    'Question',
    'First Answer',
    'Second Answer',
    'Third Answer',
    'Fourth Answer',
    'Fifth Answer',
    'Correct Answer',
    'Response',
    'Later Review',
    'Not Clear',
    'Success',
  ],

  // Health Score Max Scores
  HS__MAX_SCORE: 100,
  HS__USED_FEATURES_MAX_SCORE: 50,
  HS__ACTIVITY_MAX_SCORE: 30,
  HS__SURVEYS_MAX_SCORE: 10,
  HS__SENTIMENT_CSM_MAX_SCORE: 5,
  HS__SENTIMENT_TICKETS_MAX_SCORE: 5,
  HS__GREEN_LIMIT: 0.75,
  HS__YELLOW_LIMIT: 0.4,
  HS__HEALTHY_COLOR: '#04844B',
  HS__NEUTRAL_COLOR: '#FFB75D',
  HS__AT_RISK_COLOR: '#C23934',
  DESELECT__COLOR: '#000066',
  DESELECT__SECUNDARY_COLOR: '#3DCCCC',
  HS__HEALTHY_VALUE: 'Healthy',
  HS__NEUTRAL_VALUE: 'Neutral',
  HS__AT_RISK_VALUE: 'At Risk',
  TOUCHPOINT__GREEN_LIMIT: 75,
  TOUCHPOINT__YELLOW_LIMIT: 90,
  TOUCHPOINT__AR_GREEN_LIMIT: 7,

  // Support Portal Status
  ZENDESK_TICKET__STATUS__NEW: 'new',
  ZENDESK_TICKET__STATUS__NEW_BEAUTIFIED: 'N',
  ZENDESK_TICKET__STATUS__OPEN: 'open',
  ZENDESK_TICKET__STATUS__OPEN_BEAUTIFIED: 'O',
  ZENDESK_TICKET__STATUS__PENDING: 'pending',
  ZENDESK_TICKET__STATUS__PENDING_BEAUTIFIED: 'P',
  ZENDESK_TICKET__STATUS__CLOSED: 'closed',
  ZENDESK_TICKET__STATUS__CLOSED_BEAUTIFIED: 'C',
  ZENDESK_TICKET__STATUS__SOLVED: 'solved',
  ZENDESK_TICKET__STATUS__SOLVED_BEAUTIFIED: 'S',

  // Support Portal Sentiment
  ZENDESK_TICKET__SENTIMENT_POSITIVE: 'positive',
  ZENDESK_TICKET__SENTIMENT__POSITIVE_BEAUTIFIED: '+',
  ZENDESK_TICKET__SENTIMENT_NEUTRAL: 'neutral',
  ZENDESK_TICKET__SENTIMENT_NEGATIVE: 'negative',
  ZENDESK_TICKET__SENTIMENT_NEGATIVE_BEAUTIFIED: '-',

  // Support Portal Types
  ZENDESK_TICKET__TYPE__QUESTION: 'question',
  ZENDESK_TICKET__TYPE__QUESTION_BEAUTIFIED: 'Question',
  ZENDESK_TICKET__TYPE__IMPROVEMENT_REQUEST: 'improvement_request',
  ZENDESK_TICKET__TYPE__IMPROVEMENT_REQUEST_BEAUTIFIED: 'Improvement Req.',
  ZENDESK_TICKET__TYPE__BUG: 'bug',
  ZENDESK_TICKET__TYPE__BUG_BEAUTIFIED: 'Bug',
  ZENDESK_TICKET__TYPE__INSTALLATION: 'demo_installation_request',
  ZENDESK_TICKET__TYPE__INSTALLATION_BEAUTIFIED: 'Installation Req.',
  ZENDESK_TICKET__TYPE__SFMC_BUG: 'sfmc_bug',
  ZENDESK_TICKET__TYPE__SFMC_BUG_BEAUTIFIED: 'SFMC Bug',
  ZENDESK_TICKET__TYPE__ACADEMY_QUESTION: 'academy_question',
  ZENDESK_TICKET__TYPE__ACADEMY_QUESTION_BEAUTIFIED: 'Academy Question',

  // Support portal Products
  ZENDESK_TICKET__PRODUCT__SEGMENT: 'deselect_segment',
  ZENDESK_TICKET__PRODUCT__SEGMENT__BEAUTIFIED: 'Segment',
  ZENDESK_TICKET__PRODUCT__SEARCH: 'deselect_search',
  ZENDESK_TICKET__PRODUCT__SEARCH__BEAUTIFIED: 'Search',
  ZENDESK_TICKET__PRODUCT__ENGAGE: 'deselect_engage',
  ZENDESK_TICKET__PRODUCT__ENGAGE__BEAUTIFIED: 'Engage',
  ZENDESK_TICKET__PRODUCT__CONNECT: 'deselect_connect',
  ZENDESK_TICKET__PRODUCT__CONNECT__BEAUTIFIED: 'Connect',
  ZENDESK_TICKET__PRODUCT__ACADEMY: 'deselect_academy',
  ZENDESK_TICKET__PRODUCT__ACADEMY__BEAUTIFIED: 'Academy',

  // Metabase
  METABASE__BASE_URL: 'https://metabase.deselect.com',

  // Feature Requests
  PENDO_FEEDBACK_STATUS__NOT_REVIEWED: 'Not Reviewed',
  PENDO_FEEDBACK_STATUS__AWAITING_FEEDBACK: 'Gauging Demand',
  PENDO_FEEDBACK_STATUS__PLANNED: 'Planned',
  PENDO_FEEDBACK_STATUS__BUILDING: 'Building',
  PENDO_FEEDBACK_STATUS__RELEASED: 'Released',
  PENDO_FEEDBACK_STATUS__DECLINED: 'Declined',

  // Churn Probability
  HS__CHURN_PROB__HIGH: 'High',
  HS__CHURN_PROB__MEDIUM: 'Medium',
  HS__CHURN_PROB__LOW: 'Low',
  HS__CHURN_PROB__UNKNOWN: 'Unknown',

  // Customer Tiers
  HS__CUSTOMER_TIER__ELITE: 'advanced',
  HS__CUSTOMER_TIER__PREMIER: 'plus',
  HS__CUSTOMER_TIER__CHAMPIONSHIP: 'enable',
};

export default Constants;
