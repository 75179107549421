import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledCollapse, Button, CardBody, Card,
} from 'reactstrap';

import moment from 'moment-timezone';
import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';
import { MenuContext } from '../../../../MenuContext';

import OrganisationsAPI from '../../../../api/organisations';
import UsersAPI from '../../../../api/users';

import Swal from '../../../../helpers/Swal';

import Filter from './Filter';

import './style.scss';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import DisabledFeaturesModal from './DisabledFeatures/DisabledFeaturesModal';

const OrganisationsDataset = ({ product }) => {
  const menuContext = useContext(MenuContext);
  const [, setMenuSelected] = menuContext.menuSelected;
  const [, setMenuParameters] = menuContext.menuParameters;

  const [results, setResults] = useContext(ResultsContext);
  const [state, setState] = useContext(UtilsContext);
  const [previousAdditionalFolderIds, setPreviousAdditionalFolderIds] = useState('');

  const {
    segmentOrganisationsData, engageOrganisationsData, filteredResults,
  } = results;
  const organisationsData =
     product === Constants.DESELECT_SEGMENT ? segmentOrganisationsData : engageOrganisationsData;
  const [resultsList, setResultsList] = useState([]);
  const { showingFilteredResults, filter } = state;
  const [loading, setLoading] = useState(false);

  // Organisation variables
  const [name, setName] = useState('');
  const [hubspotID, setHubspotID] = useState('');
  const [zendeskID, setZendeskID] = useState('');
  const [type, setType] = useState('');
  const [token, setToken] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const [authenticationBaseUrl, setAuthenticationBaseUrl] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [loginEndpoint, setLoginEndpoint] = useState('');
  const [logoutEndpoint, setLogoutEndpoint] = useState('');
  const [businessUnits, setBusinessUnits] = useState([]);
  const [isActive, setActive] = useState(false);
  const [edition, setEdition] = useState(null);
  const [licenses, setLicenses] = useState(0);
  const [latePayment, setLatePayment] = useState(false);
  const [hasS2SIP, setHasS2SIP] = useState(false);
  const [server2ServerClientId, setServer2ServerClientId] = useState('');
  const [server2ServerClientSecret, setServer2ServerClientSecret] = useState('');
  const [hasS2SClientId, setHasS2SClientId] = useState(false);
  const [hasS2SClientSecret, setHasS2SClientSecret] = useState(false);
  const [features, setFeatures] = useState([]);
  const [orgFeatures, setOrgFeatures] = useState({});
  const [orgEdition, setOrgEdition] = useState('');
  const [showAdvertContainer, setShowAdvertContainer] = useState(false);
  const [sfmcInfo, setSfmcInfo] = useState({
    businessUnits: null,
    activeUsers: null,
    lastUpdated: null,
    totalNumberOFSQLQueries: null,
  });

  const filterResultsHandler = async (e, filterBy, searchText) => {
    setLoading(true);
    const filterCriteria = {};

    // If filtering by enterprise id
    if (filterBy === Constants.FILTER_BY__ENTERPRISE_BUSINESS_UNIT_ID) {
      filterCriteria.enterpriseBusinessUnitId = searchText;
    } else if (filterBy === Constants.FILTER_BY__NAME) {
      // Filtering by name of the organisation
      filterCriteria.name = { $regex: searchText, $options: 'i' };
    }

    try {
      // Get filtered results
      const res = await OrganisationsAPI.getOrganisations(product, filterCriteria);

      // Set state
      setState({ ...state, showingFilteredResults: true, filter: searchText });

      // Show filtered results
      setResults({ ...results, filteredResults: res.data });

      // Stop loading
      setLoading(false);
    } catch (error) {
      // Stop loading
      setLoading(false);

      // Fire error
      Swal.fireError(
        'Oops...',
        `Something unexpected happened: ${error.message || error}`,
      );
    }
  };

  /**
   * Stops the filtering of results
   * @returns {void}
   */
  const handleStopFiltering = () => {
    // Reset values
    setResultsList(organisationsData);
    setState({ ...state, showingFilteredResults: false, filter: null });
    setResults({ ...results, filteredResults: [] });
  };

  // UseEffect for setting results' lists
  useEffect(() => {
    // If filtering...
    if ((filteredResults && filteredResults.length) || showingFilteredResults) {
      setResultsList(filteredResults);
    } else {
      // Show unfiltered data
      setResultsList(organisationsData);
    }
    // eslint-disable-next-line
  }, [organisationsData, filteredResults]);

  /**
   * Set Business Unit isActive property
   * @param {string} id - Id of the Business Unit
   * @param {boolean} isBusinessUnitActive - New state
   * @returns {void}
   */
  const setActiveBusinessUnit = (id, isBusinessUnitActive) => {
    const BU = businessUnits.find(bu => bu._id === id);

    if (BU) {
      BU.isActive = isBusinessUnitActive;

      setBusinessUnits([...businessUnits]);
    } else {
      Swal.fireError('Oops...', 'Unable to find the Business Unit.');
    }
  };

  /**
   * Set Business Unit Name property
   * @param {string} id - Id of the Business Unit
   * @param {string} businessUnitName - New name for Business Unit
   * @returns {void}
   */
  const setBusinessUnitName = (id, businessUnitName) => {
    const BU = businessUnits.find(bu => bu._id === id);

    if (BU) {
      BU.name = businessUnitName;

      setBusinessUnits([...businessUnits]);
    } else {
      Swal.fireError('Oops...', 'Unable to find the Business Unit.');
    }
  };

  /**
   * Set Business Unit additionalFolderIds property
   * @param {string} id - Id of the Business Unit
   * @param {string} additionalFolderIds - New additionalFolderIds for Business Unit
   * @returns {void}
   */
  const setAdditionalFolderIds = (id, additionalFolderIds) => {
    const BU = businessUnits.find(bu => bu._id === id);

    // Allow deleting a trailing comma
    if (additionalFolderIds.slice(-1) === ',' && additionalFolderIds.length < previousAdditionalFolderIds.length) {
      // eslint-disable-next-line no-param-reassign
      additionalFolderIds = additionalFolderIds.substr(0, additionalFolderIds.length - 1);
    }

    // Convert comma separate list into a string
    const additionalFolderIdsArray = additionalFolderIds.split(',');
    // Convert all array entries to numbers
    const additionalFolderIdsNumbersArray = additionalFolderIdsArray.map(i => Number(i));

    setPreviousAdditionalFolderIds(additionalFolderIds);

    if (BU) {
      BU.additionalFolderIds = additionalFolderIdsNumbersArray;

      setBusinessUnits([...businessUnits]);
    } else {
      Swal.fireError('Oops...', 'Unable to find the Business Unit.');
    }
  };

  /**
   * Clean all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setName('');
    setHubspotID('');
    setZendeskID('');
    setType('');
    setToken('');
    setAuthenticationBaseUrl('');
    setBusinessUnits([]);
    setActive(false);
    setEdition(null);
    setLicenses(0);
    setLatePayment(false);
    setHasS2SIP(false);
    setServer2ServerClientId('');
    setServer2ServerClientSecret('');
  };

  /**
   * Handler called when saving an organisation
   * @returns {void}
   */
  const handleSave = async () => {
    // Set properties that apply to both products
    const newData = {
      name,
      isActive,
      type,
      licenses,
    };

    if (product === Constants.DESELECT_SEGMENT) {
      // run through updated features, and update original features object
      features.forEach((feature) => {
        Object.entries(orgFeatures).forEach((f) => {
          if (feature.feature === f[0]) {
            orgFeatures[feature.feature] = feature.setting;
          }
        });
      });

      /*
       * Pass along changes to features if edition was not changed
       * (because edition change will trigger change in feature flags in the backend)
       */
      if (edition === orgEdition) {
        newData.features = orgFeatures;
      }

      newData.licenses = licenses;
      newData.hubspotID = hubspotID;
      newData.zendeskID = zendeskID;
      newData.token = token;
      newData.authenticationBaseUrl = authenticationBaseUrl;
      newData.businessUnits = businessUnits;
      newData.edition = edition;
      newData.latePayment = latePayment;
      newData.hasServer2ServerInstalledPackage = hasS2SIP;
      newData.server2ServerClientId = server2ServerClientId;
      newData.server2ServerClientSecret = server2ServerClientSecret;

      // if values not changed delete them from newData
      if (server2ServerClientId === '') {
        delete newData.server2ServerClientId;
      }
      if (server2ServerClientSecret === '') {
        delete newData.server2ServerClientSecret;
      }
    } else if (product === Constants.DESELECT_ENGAGE) {
      newData.engageLicenses = licenses;
    }

    try {
      let res;
      if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
        res = await OrganisationsAPI.updateOrganisation(null, state.id, product, newData);
      } else if (state.eventType === Constants.EVENT__TYPE__NEW) {
        res = await OrganisationsAPI.insertOrganisation(null, product, newData);
      }

      const newArray = [...organisationsData];
      newArray[state.index] = res.data;
      setResults({ ...results, organisationsData: newArray });

      setState({ ...state, isOpen: false, eventType: '' });
      Swal.fireSuccess('Saved!', 'The organisation has been saved.');

      cleanFields();
    } catch (error) {
      Util.handleError(error);
    }
  };

  /**
   * Handler called when canceling an organisation modal
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler for opening advert modal container
   * @returns {void}
   */
  const handleOpenAdvertList = () => {
    setState({ ...state, isOpen: false });
    setShowAdvertContainer(true);
  };

  /**
   * Handler for toggle advert modal container
   * @returns {void}
   */
  const toggleAdvertList = () => {
    setShowAdvertContainer(!showAdvertContainer);
  };

  /**
   * handler to open link to Adoption Dashboard
   * @param {object} event - Event object
   *  @returns {void}
   *
   */
  const openAdoptionDashboard = async (event) => {
    event.preventDefault();
    const linkName = event.target.dataset.link;
    const orgId = event.target.dataset.id;
    setMenuSelected(linkName);
    setMenuParameters({ orgId, product });
  };

  /**
   * Set SFMC authentication endpoints based on token
   * @param {string} newBaseUrl - Updated Base Url
   * @param {string} newToken - Updated Token
   * @returns {void}
   */
  const reloadUris = (newBaseUrl, newToken) => {
    // used new values if passed as parameter, otherwise use state
    const url = newBaseUrl || baseUrl;
    const t = newToken || token;

    setRedirectUri(`${url}/auth/${t}/oauth2`);
    setLoginEndpoint(`${url}/auth/${t}/login`);
    setLogoutEndpoint(`${url}/auth/${t}/logout`);
  };

  /**
   * Handler called when token is changed
   * @param {string} newToken - New value for token
   * @returns {void}
   */
  const handleChangeToken = (newToken) => {
    setToken(newToken);
    reloadUris(null, newToken);
  };

  /**
   *  Create string of 12 random characters
   * @returns {void}
   */
  const generateNewToken = () => {
    const N = 12;
    const newToken = Array(N + 1).join((Math.random().toString(36) + '00000000000000000').slice(2, 18)).slice(0, N);
    handleChangeToken(newToken);
  };

  /**
   * Handler called when the Base url endpoint changes
   * @param {string} newBaseUrl - New Base Url
   * @returns {void}
   */
  const handleChangeBaseUrl = (newBaseUrl) => {
    setBaseUrl(newBaseUrl);
    reloadUris(newBaseUrl);
  };

  /**
   * Handler called when feature flag is changed
   * @param {string} event - Event object
   * @param {number} featureIndex - Index of the feature we want to change
   * @param {object} featureName - Name of feature
   * @returns {void}
   */
  const handleChangeFeature = (event, featureIndex) => {
    const newSetting = event.target.checked;
    setFeatures(features.map((feature, index) => {
      // Ask for confirmation before changing the feature flag
      if (featureIndex === index &&
        /* eslint-disable-next-line no-alert */
        window.confirm(`Are you sure you want to change feature flag
         ${features[featureIndex].feature}
         to
         ${newSetting}?`)) {
        return { ...feature, setting: newSetting };
      }
      return { ...feature };
    }));
  };

  /**
   * Handler called when editing an organisation
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = async (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    const { index } = event.target.dataset;

    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });

    // Populate inputs
    const org =
    product === Constants.DESELECT_SEGMENT ?
      await OrganisationsAPI.getSegmentOrganisation(null, id) :
      await OrganisationsAPI.getEngageOrganisation(null, id);
    const singleItem = org.data;

    setName(singleItem.name);
    setHubspotID(singleItem.hubspotID);
    setZendeskID(singleItem.zendeskID);
    setType(singleItem.type);
    handleChangeToken(singleItem.token);
    handleChangeBaseUrl(Constants.BASE_URL__DESELECT_SEGMENT);
    setAuthenticationBaseUrl(singleItem.authenticationBaseUrl);
    setBusinessUnits(singleItem.businessUnits);
    setActive(singleItem.isActive);
    setEdition(singleItem.edition);
    setOrgEdition(singleItem.edition); // keep the original edition
    setLicenses(product === Constants.DESELECT_SEGMENT ? singleItem.licenses : singleItem.engageLicenses);
    setLatePayment(singleItem.latePayment);
    setHasS2SIP(singleItem.hasServer2ServerInstalledPackage);
    setHasS2SClientId(singleItem.hasS2SClientId);
    setHasS2SClientSecret(singleItem.hasS2SClientSecret);

    if (product === Constants.DESELECT_SEGMENT) {
    // Save original features object
      setOrgFeatures(singleItem.features);
      // Convert features object into an array
      const featuresArray = [];
      Object.entries(singleItem.features).forEach((f) => {
        featuresArray.push({ feature: f[0], setting: f[1] });
      });
      setFeatures(featuresArray);
      setSfmcInfo(singleItem.sfmcData || {});
    }
  };

  const userHasEditRights =
    Util.userHasPermission(
      Constants.USER__PERMISSION__CSM,
      Constants.USER__PERMISSION__WRITE,
    );

  /**
   * Navigates to users menu to show a particular organisation's users
   * @param {object} org - The organisation object
   * @returns {void}
   */
  const handleNavigateToUsers = async (org) => {
    let { usersData } = results;

    const data = await UsersAPI.getUsers(product, null);
    usersData = data.users;

    setState({ ...state, showingFilteredUsers: true, selectedOrg: org });
    if (product === Constants.DESELECT_SEGMENT) {
      setMenuSelected(Constants.MENU__NAME__SEGMENT_USERS);
      setResults({ ...results, segmentUsersData: usersData });
    } else if (product === Constants.DESELECT_ENGAGE) {
      setMenuSelected(Constants.MENU__NAME__ENGAGE_USERS);
      setResults({ ...results, engageUsersData: usersData });
    }
  };

  /**
   * Updates late payment org flag, after user confirms they want to continue with the change
   * @returns {void}
   */
  const handleSetLatePayment = async () => {
    const message =
      `Are you sure you want to ${latePayment ? 'deactivate' : 'activate'} the late payment notification?`;

    const confirmation = await Swal.fireWarning({
      icon: 'warning',
      title: message,
      confirmButtonText: 'Yes, update!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
    });

    if (confirmation.value) setLatePayment(!latePayment);
  };

  /**
   * Updates is active org flag, after user confirms they want to continue with the change
   * @returns {void}
   */
  const handleSetIsActive = async () => {
    const message =
      `Are you sure you want to ${isActive ? 'deactivate' : 'activate'} the organisation?`;

    const confirmation = await Swal.fireWarning({
      icon: 'warning',
      title: message,
      confirmButtonText: 'Yes, update!',
      confirmButtonColor: '#3085d6',
      showCancelButton: true,
      cancelButtonColor: '#d33',
    });

    if (confirmation.value) setActive(!isActive);
  };

  return (
    <div className="organisation">
      <Filter
        handleStopFiltering={handleStopFiltering}
        showingFilteredItems={showingFilteredResults}
        filterResultsHandler={filterResultsHandler}
        loading={loading}
        filter={filter}
      />
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="eid">
                EID
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="hid">
                HID
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="zid">
                ZID
              </div>
            </th>

            <th className="name-column" scope="col">
              <div className="slds-truncate" title="Name">
                Name
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Users">
                Users
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Type">
                Type
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Business Units">
                Business Units
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Active Business Units">
                Active BU
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Is active">
                Is active
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Edition">
                Edition
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Created at">
                Created at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Late payment">
                Late payment
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {resultsList && resultsList.length > 0 ?
            resultsList.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td data-label="EID">
                  <div className="slds-truncate" title={el.enterpriseBusinessUnitId}>
                    {el.enterpriseBusinessUnitId}
                  </div>
                </td>

                <td data-label="HID">
                  <div className="slds-truncate" title={el.hubspotID}>
                    <a
                      target="_blank"
                      // eslint-disable-next-line spellcheck/spell-checker
                      rel="noreferrer"
                      href={`${Constants.HUBSPOT__COMPANY__BASE_URL}/${el.hubspotID}/`}
                      title={`Click to view ${el.name} on Hubspot`}
                    >
                      {el.hubspotID}
                    </a>
                  </div>
                </td>

                <td data-label="ZID">
                  <div className="slds-truncate" title={el.zendeskID}>
                    {el.zendeskID}
                  </div>
                </td>

                <td data-label="Name">
                  <div className="slds-truncate" title={el.name}>
                    {Util.renderAdoptionDashboardLink(el.name, el._id, openAdoptionDashboard, product)}
                  </div>
                </td>

                <td>
                  <button
                    type="button"
                    className="slds-button slds-button_neutral"
                    aria-haspopup="true"
                    onClick={() => handleNavigateToUsers(el)}
                  >
                    Users
                  </button>
                </td>

                <td>
                  <div className="slds-truncate">
                    <span className="slds-badge">{el.type}</span>
                  </div>
                </td>

                <td data-label="Business Units">
                  {el.businessUnits.length ?
                    (
                      <div className="slds-dropdown-trigger slds-dropdown-trigger_hover">
                        <button
                          type="button"
                          className="slds-button slds-button_icon slds-button_icon-border-filled"
                          aria-haspopup="true"
                        >
                          <svg className="slds-button__icon" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down" />
                          </svg>
                          <span className="slds-assistive-text">Show Business Units</span>
                        </button>
                        <div className="slds-dropdown slds-dropdown_left">
                          <ul className="slds-dropdown__list" role="menu" aria-label="Show Business Units">
                            {el.businessUnits.map(bu => (
                              <li key={bu.businessUnitId} className="slds-dropdown__item" role="presentation">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="#" role="menuitem">
                                  <span
                                    className="slds-truncate slds-border_right slds-p-right--x-small"
                                    title={bu.businessUnitId}
                                  >
                                    {bu.businessUnitId}
                                  </span>

                                  {bu.isActive ?
                                    <span className="slds-badge slds-theme_success slds-m-left_x-small">Active</span> :
                                    <span className="slds-badge slds-theme_error slds-m-left_x-small">Inactive</span>}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) :
                    null}
                </td>

                <td>
                  <div className="slds-truncate">
                    <span className="slds-badge">{el.businessUnits.filter(x => x.isActive).length}</span>
                  </div>
                </td>

                <td>
                  <div className="slds-truncate">
                    {el.isActive ?
                      <span className="slds-badge slds-theme_success">True</span> :
                      <span className="slds-badge slds-theme_error">False</span>}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate">
                    <span className="slds-badge">{el.edition}</span>
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.createdAt}>
                    {el.createdAt}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.updatedAt}>
                    {el.updatedAt}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate">
                    {el.latePayment ?
                      <span className="slds-badge slds-theme_error">True</span> :
                      <span className="slds-badge slds-theme_success">False</span>}
                  </div>
                </td>
              </tr>
            )) :
            (
              <tr>
                <td colSpan="8" className="no-results-found">No results found</td>
              </tr>
            )}
        </tbody>
      </table>
      {/* Dataset Section */}

      {/* Modal Section */}
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={state.isOpen ? 'slds-modal slds-fade-in-open' : 'slds-modal'}
      >
        <div className="slds-modal__container" style={{ fontSize: '12px', width: '60%', maxWidth: '60%' }}>
          <header className="slds-modal__header">
            <button
              type="button"
              className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
              title="Close"
            >
              <svg
                className="slds-button__icon slds-button__icon_large"
                aria-hidden="true"
              />
              <span className="slds-assistive-text">Close</span>
            </button>
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Organisations Form
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-name">
                Name
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-name"
                    placeholder="Name"
                    className="slds-input"
                    onChange={event => setName(event.target.value)}
                    value={name}
                    disabled={!userHasEditRights}
                  />
                </div>
              </label>
            </div>
            <br />

            {product === Constants.DESELECT_SEGMENT && (

              <>
                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-name">
                    Hubspot ID
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="form-element-name"
                        placeholder="HubspotID"
                        className="slds-input"
                        onChange={event => setHubspotID(event.target.value)}
                        value={hubspotID}
                        disabled={!userHasEditRights}
                      />
                    </div>
                  </label>
                </div>

                <br />
                <label className="slds-form-element__label" htmlFor="form-element-name">
                  Zendesk ID
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      id="form-element-name"
                      placeholder="ZendeskID"
                      className="slds-input"
                      onChange={event => setZendeskID(event.target.value)}
                      value={zendeskID}
                      disabled={!userHasEditRights}
                    />
                  </div>
                </label>
                <br />

                <div>
                  <Button
                    className="slds-button slds-button_neutral"
                    id="authentication-expand"
                    style={{ marginBottom: '1rem' }}
                  >
                    Authentication
                  </Button>
                  <UncontrolledCollapse toggler="#authentication-expand">
                    <Card>
                      <CardBody>

                        <div className="slds-form-element">
                          <label className="slds-form-element__label" htmlFor="form-element-token">
                            Token
                            &nbsp;
                            <button
                              type="button"
                              className="slds-button slds-button_neutral"
                              href="#"
                              onClick={generateNewToken}
                            >
                              Generate New Token
                            </button>

                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="form-element-token"
                                placeholder="Token to uniquely identify organisation if
                            no one-click installation link is used"
                                className="slds-input"
                                onChange={event => handleChangeToken(event.target.value)}
                                value={token}
                                disabled={!userHasEditRights}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="slds-form-element">
                          <label className="slds-form-element__label" htmlFor="form-element-authentication-base-uri">
                            Authentication Base URI
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="form-element-authentication-base-uri"
                                placeholder="Copy Authentication Base URI from Installed Package"
                                className="slds-input"
                                onChange={event => setAuthenticationBaseUrl(event.target.value)}
                                value={authenticationBaseUrl}
                                disabled={!userHasEditRights}
                              />
                            </div>
                          </label>
                        </div>

                        <div className="slds-form-element">
                          <label className="slds-form-element__label" htmlFor="select-app">
                            Endpoints for App:
                            <div className="slds-form-element__control">
                              <div className="slds-select_container">
                                <select
                                  className="slds-select"
                                  onChange={event => handleChangeBaseUrl(event.target.value)}
                                  id="select-app"
                                  value={baseUrl}
                                  disabled={!userHasEditRights}
                                >
                                  <option value={Constants.BASE_URL__DESELECT_SEGMENT}>DESelect Segment</option>
                                </select>
                              </div>
                            </div>
                          </label>
                        </div>

                        <div className="slds-form-element">
                          <label className="slds-form-element__label" htmlFor="form-element-redirect-uri">
                            Redirect URI
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="form-element-redirect-uri"
                                className="slds-input"
                                value={redirectUri}
                                disabled="disabled"
                              />
                            </div>
                          </label>
                        </div>

                        <div className="slds-form-element">
                          <label className="slds-form-element__label" htmlFor="form-element-login-endpoint">
                            Login Endpoint
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="form-element-login-endpoint"
                                className="slds-input"
                                value={loginEndpoint}
                                disabled="disabled"
                              />
                            </div>
                          </label>
                        </div>

                        <div className="slds-form-element">
                          <label className="slds-form-element__label" htmlFor="form-element-logout-endpoint">
                            Logout Endpoint
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id="form-element-logout-endpoint"
                                className="slds-input"
                                value={logoutEndpoint}
                                disabled="disabled"
                              />
                            </div>
                          </label>
                        </div>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>
                </div>
              </>
            )}

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="select-type">
                Type
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setType(event.target.value)}
                      id="select-type"
                      value={type}
                      disabled={!userHasEditRights}
                    >
                      <option value="" defaultValue>
                        Please select
                      </option>
                      <option value={Constants.DESELECT__ORGANISATION_TYPE__CUSTOMER}>Customer</option>
                      <option value={Constants.DESELECT__ORGANISATION_TYPE__PARTNER}>Partner</option>
                      <option value={Constants.DESELECT__ORGANISATION_TYPE__INTERNAL}>Internal</option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            {/* Users and Business Units in an organization from SFMC  */}
            {product === Constants.DESELECT_SEGMENT && (

            <div className="slds-form-element sfmc-insights">
              <h4>SFMC Insights</h4>

              {!sfmcInfo?.businessUnits?.length && !sfmcInfo.activeUsers ?
                (<div>No information for this organization yet.</div>) :
                (
                  <>
                    <div className="slds-m-top_medium">
                      {sfmcInfo.businessUnits ?
                        (
                          <details>
                            <summary>
                              <strong>Business Units: </strong>
                              {sfmcInfo.businessUnits.length}
                            </summary>
                            <ul>
                              {sfmcInfo.businessUnits.map(bu => (
                                <li key={bu.buId}>{bu.name}</li>
                              ))}
                            </ul>
                          </details>
                        ) :
                        'Data not available'}
                    </div>

                    <div className="slds-m-top_medium">
                      <strong>Active Users: </strong>
                      {sfmcInfo.activeUsers ?
                        sfmcInfo.activeUsers :
                        'Data not available'}
                    </div>

                    <div className="slds-m-top_medium">
                      <strong>SQL Queries: </strong>
                      {sfmcInfo.totalNumberOFSQLQueries ?
                        sfmcInfo.totalNumberOFSQLQueries :
                        'Data not available'}
                    </div>

                    <div className="slds-m-top_medium">
                      <strong>Last Updated: </strong>
                      {sfmcInfo.lastUpdated ?
                        moment(sfmcInfo.lastUpdated).format('MMMM Do YYYY') :
                        'Data not available'}
                    </div>
                  </>
                )}
            </div>
            )}

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-businessunit" style={{ width: '100%' }}>
                <h4>Business Units</h4>
                <div id="form-element-businessunit">
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }}>Business Unit Id</th>
                        <th style={{ width: '40%' }}>Name</th>
                        <th style={{ width: '20%' }}>Is active</th>
                        <th style={{ width: '20%' }}>Additional Folder Ids</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessUnits.map(bu => (
                        <tr key={bu.businessUnitId}>
                          <td>{bu.businessUnitId}</td>
                          <td>
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id={`form-element-business-unit-name-${bu.businessUnitId}`}
                                placeholder="Business Unit Name"
                                className="slds-input"
                                onChange={event => setBusinessUnitName(bu._id, event.target.value)}
                                value={bu.name}
                                disabled={!userHasEditRights}
                                style={{ width: '90%' }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="slds-form-element__control">
                              <div className="slds-checkbox">
                                <input
                                  onChange={() => setActiveBusinessUnit(bu._id, !bu.isActive)}
                                  type="checkbox"
                                  name={`isBusinessUnitActive_${bu._id}`}
                                  id={`isBusinessUnitActive_${bu._id}`}
                                  checked={bu.isActive}
                                  disabled={!userHasEditRights}
                                />
                                <label className="slds-checkbox__label" htmlFor={`isBusinessUnitActive_${bu._id}`}>
                                  <span className="slds-checkbox_faux" />
                                  <span className="slds-form-element__label">
                                    {bu.isActive ? 'True' : 'False'}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="slds-form-element__control">
                              <input
                                type="text"
                                id={`form-element-additional-folder-ids-${bu._id}`}
                                placeholder="Additional Folder Ids"
                                className="slds-input"
                                onChange={event => setAdditionalFolderIds(bu._id, event.target.value)}
                                value={bu.additionalFolderIds.join()}
                                disabled={!userHasEditRights}
                                style={{ width: '100%' }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </label>
            </div>
            <br />

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="isActive">
                Is active
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => handleSetIsActive()}
                      type="checkbox"
                      name="isActive"
                      id="isActive"
                      checked={isActive}
                      disabled={!userHasEditRights}
                    />
                    <label className="slds-checkbox__label" htmlFor="isActive">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {isActive ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </label>
            </div>

            {product === Constants.DESELECT_SEGMENT && (

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="latePayment">
                Is late payment
                <div className="slds-form-element__control">
                  <div className="slds-checkbox">
                    <input
                      onChange={() => handleSetLatePayment()}
                      type="checkbox"
                      name="latePayment"
                      id="latePayment"
                      checked={latePayment}
                      disabled={!userHasEditRights}
                    />
                    <label className="slds-checkbox__label" htmlFor="latePayment">
                      <span className="slds-checkbox_faux" />
                      <span className="slds-form-element__label">
                        {latePayment ? 'True' : 'False'}
                      </span>
                    </label>
                  </div>
                </div>
              </label>
            </div>
            )}

            {product === Constants.DESELECT_SEGMENT && (
              <>
                <h2>DESelect Segment</h2>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="hasServer2ServerInstalledPackage">
                    Has Server-to-Server Installed Package
                    <div className="slds-form-element__control">
                      <div className="slds-checkbox">
                        <input
                          onChange={() => setHasS2SIP(!hasS2SIP)}
                          type="checkbox"
                          name="hasServer2ServerInstalledPackageCheckbox"
                          id="hasServer2ServerInstalledPackageCheckbox"
                          checked={hasS2SIP}
                          disabled={!userHasEditRights}
                        />
                        <label className="slds-checkbox__label" htmlFor="hasServer2ServerInstalledPackageCheckbox">
                          <span className="slds-checkbox_faux" />
                          <span className="slds-form-element__label">
                            {hasS2SIP ? 'True' : 'False'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-automations-client-id">
                    DESelect Automations Client Id
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="form-element-automations-client-id"
                        placeholder={hasS2SClientId ?
                          '••••••••••••••••••••••••' :
                          'DESelect Automations installed package Client Id'}
                        className="slds-input"
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setServer2ServerClientId(newValue);
                        }}
                        value={server2ServerClientId || ''}
                        disabled={!userHasEditRights}
                      />
                    </div>
                  </label>
                </div>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-automations-client-secret">
                    DESelect Automations Client Secret
                    <div className="slds-form-element__control">
                      <input
                        type="text"
                        id="form-element-automations-client-secret"
                        placeholder={hasS2SClientSecret ?
                          '••••••••••••••••••••••••' :
                          'DESelect Automations installed package Client Secret'}
                        className="slds-input"
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setServer2ServerClientSecret(newValue);
                        }}
                        value={server2ServerClientSecret || ''}
                        disabled={!userHasEditRights}
                      />
                    </div>
                  </label>
                </div>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="form-element-licenses">
                    Licenses
                    <div className="slds-form-element__control">
                      <input
                        type="number"
                        id="form-element-licenses"
                        placeholder="Licenses"
                        className="slds-input"
                        onChange={event => setLicenses(event.target.value)}
                        value={licenses}
                        disabled={!userHasEditRights}
                      />
                    </div>
                  </label>
                </div>

                <div className="slds-form-element">
                  <label className="slds-form-element__label" htmlFor="select-edition">
                    Edition
                    <div className="slds-form-element__control">
                      <div className="slds-select_container">
                        <select
                          className="slds-select"
                          onChange={event => setEdition(event.target.value)}
                          id="select-edition"
                          value={edition}
                          disabled={!userHasEditRights}
                        >
                          <option value="" defaultValue>
                            Please select
                          </option>
                          <option value={Constants.DESELECT__EDITION__ADVANCED}>Advanced</option>
                          <option value={Constants.DESELECT__EDITION__PLUS}>Plus</option>
                          <option value={Constants.DESELECT__EDITION__ENABLE}>Enable</option>
                          <option value={Constants.DESELECT__EDITION__ESSENTIALS}>Free</option>
                        </select>
                      </div>
                    </div>
                  </label>
                </div>

                <br />
                <Button
                  className="slds-button slds-button_neutral"
                  id="authentication-expand"
                  style={{ marginBottom: '1rem' }}
                >
                  Features
                </Button>
                <Button
                  className="slds-button slds-button_neutral"
                  id="features-container"
                  onClick={handleOpenAdvertList}
                  style={{ marginBottom: '1rem' }}
                >
                  Adverts
                </Button>
                <UncontrolledCollapse toggler="#authentication-expand">
                  <Card>
                    <CardBody>
                      <b>
                        <font color="red">
                          Be very careful when changing feature flags, it impacts the functionality users
                          have access to immediately.
                          <br />
                          Feature flags are automatically set correctly in the backend when switching editions.
                          Hence, when changing edition, feature flags are ignored.
                          First switch to another edition and Save, then reload and change feature flags.
                        </font>

                      </b>

                      <div className="slds-form-element">
                        {features.map((feature, index) => {
                          if (typeof feature.setting === 'boolean') {
                            return (
                              <div className="slds-form-element__control" key={`control-feature_${feature.feature}`}>
                                <div className="slds-checkbox">
                                  <input
                                    onChange={e => handleChangeFeature(e, index, feature.feature)}
                                    type="checkbox"
                                    name={`feature_${feature.feature}`}
                                    id={`feature_${feature.feature}`}
                                    key={`feature_${feature.feature}`}
                                    checked={feature.setting}
                                    disabled={!userHasEditRights}
                                  />
                                  <label className="slds-checkbox__label" htmlFor={`feature_${feature.feature}`}>
                                    <span className="slds-checkbox_faux" />
                                    <span className="slds-form-element__label">
                                      {feature.feature}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>

                    </CardBody>
                  </Card>
                </UncontrolledCollapse>

              </>
            )}

            {product === Constants.DESELECT_ENGAGE && (
            <>
              <h2>DESelect Engage</h2>

              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="form-element-licenses">
                  Licenses
                  <div className="slds-form-element__control">
                    <input
                      type="number"
                      id="form-element-licenses"
                      placeholder="Licenses"
                      className="slds-input"
                      onChange={event => setLicenses(event.target.value)}
                      value={licenses}
                      disabled={!userHasEditRights}
                    />
                  </div>
                </label>
              </div>
            </>
            )}

          </div>

          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div
        className={state.isOpen ? 'slds-backdrop slds-backdrop_open' : ''}
      />
      {/* Modal Section */}
      <DisabledFeaturesModal
        showModal={showAdvertContainer}
        toggleAdvertList={toggleAdvertList}
        features={features}
      />
    </div>
  );
};

OrganisationsDataset.propTypes = {
  product: PropTypes.oneOf([Constants.DESELECT_SEGMENT, Constants.DESELECT_ENGAGE]).isRequired,
};

export default OrganisationsDataset;
