import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResultsContext } from '../../../../ResultsContext';
import { UtilsContext } from '../../../../UtilsContext';

import AdminUsersAPI from '../../../../api/adminusers';
import AdminUserModal from './Modals/AdminUserModal';

import Swal from '../../../../helpers/Swal';

import Constants from '../../../../constants/constants';
import Util from '../../../../util';

const AdminUsersDataset = ({ validate }) => {
  const [state, setState] = useContext(UtilsContext);

  // User properties
  const [results] = useContext(ResultsContext);
  const { adminUsersData } = results;
  const [usersList, setUsersList] = useState([]);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [asanaUserId, setAsanaUserId] = useState('');
  const [asanaProjectId, setAsanaProjectId] = useState('');
  const [exactOnlineUserName, setExactOnlineUserName] = useState('');
  const [permissionDefaultSetting, setPermissionDefaultSetting] = useState();
  const [developerPermission, setDeveloperPermission] = useState('');
  const [financePermission, setFinancePermission] = useState('');
  const [csmPermission, setCsmPermission] = useState('');
  const [productPermission, setProductPermission] = useState('');
  const [marketingPermission, setMarketingPermission] = useState('');
  const [reportsPermission, setReportsPermission] = useState('');
  const [setupPermission, setSetupPermission] = useState('');

  // ~= to componentDidMount and componentDidUnmount
  useEffect(() => {
    async function getAdminUsers() {
      const res = await AdminUsersAPI.getAdminUsers(null);
      setUsersList(res.data);
    }

    try {
      if (adminUsersData && adminUsersData.length && usersList !== adminUsersData) {
        setUsersList(adminUsersData);
      } else {
        getAdminUsers();
      }
    } catch (error) {
      Util.handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminUsersData, setUsersList]);

  /**
   * Cleans all the fields
   * @returns {void}
   */
  const cleanFields = () => {
    setUserName('');
    setPassword('');
    setPermissionDefaultSetting('');
    setAsanaUserId('');
    setAsanaProjectId('');
    setExactOnlineUserName('');
    setDeveloperPermission('');
    setFinancePermission('');
    setCsmPermission('');
    setProductPermission('');
    setMarketingPermission('');
    setSetupPermission('');
  };

  /**
   * Handler called when updating a user
   * @returns {void}
   */
  const handleSave = async () => {
    let newArray;

    const validationData = {
      username,
      password,
    };

    // validate input data without orgId
    const validateResult = validate(validationData);

    if (validateResult.length > 0) {
      Swal.fireWarning('', 'Please, fill out all the fields.');
    } else {
      try {
        const permissions = {
          developer: developerPermission,
          finance: financePermission,
          csm: csmPermission,
          product: productPermission,
          marketing: marketingPermission,
          reports: reportsPermission,
          setup: setupPermission,
        };

        if (state.eventType === Constants.EVENT__TYPE__UPDATE) {
          const newData = {
            asanaUserId,
            asanaProjectId,
            exactOnlineUserName,
            permissions,
          };

          const res = await AdminUsersAPI.updateAdminUser(null, state.id, newData);

          newArray = [...usersList];
          // Find the index of the updated user
          const index = newArray.findIndex(user => user._id === state.id);
          newArray[index] = res.data;

          Swal.fireSuccess('Updated!', 'The admin user has been updated.');
          setState({ ...state, isOpen: false, eventType: '' });
        } else {
          const newData = {
            asanaUserId,
            asanaProjectId,
            exactOnlineUserName,
            permissions,
            username,
            password,
          };

          const res = await AdminUsersAPI.createAdminUser(null, newData);

          if (res.data) {
            newArray = [...usersList, res.data];
            Swal.fireSuccess('Created!', 'The admin user has been created.');
            setState({ ...state, isOpen: false, eventType: '' });
          } else {
            Swal.fireWarning('', 'Username already in use');
          }
        }

        cleanFields();
        setUsersList(newArray);
      } catch (error) {
        Util.handleError(error);
      }
    }
  };

  /**
   * Handler called when canceling the editing of an user
   * @returns {void}
   */
  const handleCancel = () => {
    setState({ ...state, isOpen: false });
    cleanFields();
  };

  /**
   * Handler called when editing an user
   * @param {object} event - JS Event
   * @returns {void}
   */
  const handleEdit = (event) => {
    event.preventDefault();
    const { id, index } = event.target.dataset;
    setState({
      ...state,
      isOpen: true,
      eventType: Constants.EVENT__TYPE__UPDATE,
      id,
      index,
    });
    // Prefilled textbox
    const singleItem = usersList.filter(e => e._id === id)[0];
    setUserName(singleItem.username);
    setPassword(singleItem.password);
    setAsanaUserId(singleItem?.asanaUserId || '');
    setAsanaProjectId(singleItem?.asanaProjectId || '');
    setExactOnlineUserName(singleItem?.exactOnlineUserName || '');
    setDeveloperPermission(singleItem.permissions?.developer);
    setFinancePermission(singleItem.permissions?.finance);
    setCsmPermission(singleItem.permissions?.csm);
    setProductPermission(singleItem.permissions?.product);
    setMarketingPermission(singleItem.permissions?.marketing);
    setReportsPermission(singleItem.permissions?.reports);
    setSetupPermission(singleItem.permissions?.setup);
  };

  return (
    <div className="Admin Users">
      {/* Dataset Section */}
      <table
        className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_fixed-layout"
        style={{ marginBottom: '10px', fontSize: '12px' }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="id-column" scope="col">
              <div className="slds-truncate" title="_id">
                _id
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Username">
                Username
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Is Initiated">
                Is Initialized
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Is Active">
                Is Active
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Updated at">
                Updated at
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Asana UserId">
                Asana UserID
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="Asana ProjectID">
                Asana ProjectID
              </div>
            </th>

            <th scope="col">
              <div className="slds-truncate" title="ExactOnline Username">
                ExactOnline Username
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {usersList ?
            usersList.map((el, i) => (
              <tr key={el._id} className="slds-hint-parent">
                <td data-label="_id">
                  <div title={el._id}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={handleEdit}
                      data-id={el._id}
                      data-index={i}
                    >
                      {el._id}
                    </a>
                  </div>
                </td>

                <td>
                  <div className="slds-truncate">
                    {el.username}
                  </div>
                </td>

                <td data-label="Is Initialized">
                  <div className="slds-truncate">
                    {el.isInitialized ?
                      <span className="slds-badge slds-theme_success">True</span> :
                      <span className="slds-badge slds-theme_error">False</span>}
                  </div>
                </td>

                <td data-label="Is Active">
                  <div className="slds-truncate">
                    {el.isActive ?
                      <span className="slds-badge slds-theme_success">True</span> :
                      <span className="slds-badge slds-theme_error">False</span>}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.updatedAt}>
                    {el.updatedAt}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.asanaUserId}>
                    {el.asanaUserId}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.asanaProjectId}>
                    {el.asanaProjectId}
                  </div>
                </td>

                <td>
                  <div className="slds-truncate" title={el.exactOnlineUserName}>
                    {el.exactOnlineUserName}
                  </div>
                </td>
              </tr>
            )) :
            null}
        </tbody>
      </table>

      <div
        className={state.isOpen ? 'slds-backdrop slds-backdrop_open' : ''}
      />
      {(state.eventType === Constants.EVENT__TYPE__NEW || state.eventType === Constants.EVENT__TYPE__UPDATE) ?
        (
          <AdminUserModal
            username={username}
            setUserName={setUserName}
            password={password}
            setPassword={setPassword}
            asanaUserId={asanaUserId}
            setAsanaUserId={setAsanaUserId}
            asanaProjectId={asanaProjectId}
            setAsanaProjectId={setAsanaProjectId}
            exactOnlineUserName={exactOnlineUserName}
            setExactOnlineUserName={setExactOnlineUserName}
            permissionDefaultSetting={permissionDefaultSetting}
            setPermissionDefaultSetting={setPermissionDefaultSetting}
            developerPermission={developerPermission}
            setDeveloperPermission={setDeveloperPermission}
            financePermission={financePermission}
            setFinancePermission={setFinancePermission}
            csmPermission={csmPermission}
            productPermission={productPermission}
            setCsmPermission={setCsmPermission}
            marketingPermission={marketingPermission}
            setProductPermission={setProductPermission}
            setMarketingPermission={setMarketingPermission}
            setReportsPermission={setReportsPermission}
            reportsPermission={reportsPermission}
            setupPermission={setupPermission}
            setSetupPermission={setSetupPermission}
            isOpen={state.isOpen}
            eventType={state.eventType}
            handleCancel={handleCancel}
            handleSave={handleSave}
          />
        ) :
        null}
    </div>
  );
};

AdminUsersDataset.propTypes = {
  /**
   * Function to validate the user input
   */
  validate: PropTypes.func.isRequired,
};

export default AdminUsersDataset;
