import Constants from './constants/constants';
import Util from './util';

export default {
  getPermissionsSegment: () => [
    {
      name: Constants.MENU__NAME__SEGMENT_ENGAGEMENT_DASHBOARD,
      path: '/engagement-dashboard',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__REPORTS)],
    }, {
      name: Constants.MENU__NAME__SEGMENT_ESSENTIALS_ENGAGEMENT_DASHBOARD,
      path: '/essentials-engagement-dashboard',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__REPORTS)],
    }, {
      name: Constants.MENU__NAME__DASHBOARD,
      path: '/dashboard',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__REPORTS)],
    }, {
      name: Constants.MENU__NAME__CHANGELOG,
      path: '/changelogs',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__DEVELOPER),
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    }, {
      name: Constants.MENU__NAME__SELECTION_RUN_LOG,
      path: '/selection-run-logs',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__DEVELOPER),
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    }, {
      name: Constants.MENU__NAME__SEGMENT_ORGANISATIONS,
      path: '/segment-organisations',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM),
        Util.userHasPermission(Constants.USER__PERMISSION__REPORTS)],
    }, {
      name: Constants.MENU__NAME__SEGMENT_USERS,
      path: '/segment-users',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    }, {
      name: Constants.MENU__NAME__FEATURE,
      path: '/features',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__DEVELOPER, Constants.USER__PERMISSION__WRITE),
      ],
    }, {
      name: Constants.MENU__NAME__NOTIFICATION,
      path: '/notifications',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    },
    {
      name: Constants.MENU__NAME__ALERTS,
      path: '/settings',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    }, {
      name: Constants.MENU__NAME__USER_NOTIFICATION,
      path: '/user-notifications',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    }, {
      name: Constants.MENU__NAME__SETTINGS,
      path: '/settings',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__DEVELOPER, Constants.USER__PERMISSION__WRITE)],
    }, {
      name: Constants.MENU__NAME__TIPS,
      path: '/tips',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM),
        Util.userHasPermission(Constants.USER__PERMISSION__PRODUCT),
        Util.userHasPermission(Constants.USER__PERMISSION__MARKETING)],
    },
  ],

  getPermissionsEngage: () => [
    {
      name: Constants.MENU__NAME__ENGAGE_ORGANISATIONS,
      path: '/engage-organisations',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM),
        Util.userHasPermission(Constants.USER__PERMISSION__REPORTS)],
    }, {
      name: Constants.MENU__NAME__ENGAGE_USERS,
      path: '/engage-users',
      permissions: [
        Util.userHasPermission(Constants.USER__PERMISSION__CSM)],
    },
  ],
};
