import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Constants from '../../../../../constants/constants';
import Util from '../../../../../util';
import '../style.scss';

const AdminUserModal = ({
  username, setUserName, password, setPassword, asanaUserId,
  setAsanaUserId, asanaProjectId, setAsanaProjectId, exactOnlineUserName, setExactOnlineUserName,
  setPermissionDefaultSetting, permissionDefaultSetting,
  setDeveloperPermission, developerPermission,
  setFinancePermission, financePermission,
  setCsmPermission, csmPermission,
  productPermission, setProductPermission,
  marketingPermission, setMarketingPermission,
  setReportsPermission, reportsPermission,
  setSetupPermission, setupPermission,
  isOpen, eventType,
  handleCancel, handleSave,
}) => {
  const adminUserBackdropClassName = classNames(
    '',
    { 'slds-backdrop slds-backdrop_open': isOpen },
  );

  const adminUserModalClassName = classNames(
    'slds-modal',
    { 'slds-fade-in-open': isOpen },
  );

  /**
   * Handler called when changing the Permission Default Setting
   * @param {String} permissionDefault - selected permissionDefault
   * @returns {void}
   */
  const changePermissionDefaultSetting = (permissionDefault) => {
    setPermissionDefaultSetting(permissionDefault);
    switch (permissionDefault) {
      case Constants.PERMISSION_DEFAULT__AE:
        setDeveloperPermission('');
        setFinancePermission('');
        setCsmPermission('');
        setReportsPermission(Constants.USER__PERMISSION__READ);
        setSetupPermission('');
        break;

      case Constants.PERMISSION_DEFAULT__CSM:
        setDeveloperPermission('');
        setFinancePermission('');
        setCsmPermission(Constants.USER__PERMISSION__WRITE);
        setReportsPermission(Constants.USER__PERMISSION__READ);
        setSetupPermission('');
        break;

      case Constants.PERMISSION_DEFAULT__ENGINEER:
        setDeveloperPermission(Constants.USER__PERMISSION__WRITE);
        setFinancePermission('');
        setCsmPermission('');
        setReportsPermission(Constants.USER__PERMISSION__READ);
        setSetupPermission('');
        break;

      case Constants.PERMISSION_DEFAULT__FINANCE:
        setDeveloperPermission('');
        setFinancePermission(Constants.USER__PERMISSION__WRITE);
        setCsmPermission('');
        setReportsPermission(Constants.USER__PERMISSION__READ);
        setSetupPermission('');
        break;

      default:
        setDeveloperPermission('');
        setFinancePermission('');
        setCsmPermission('');
        setReportsPermission(Constants.USER__PERMISSION__READ);
        setSetupPermission('');
        break;
    }
  };

  const userHasEditRights = Util.userHasPermission(
    Constants.USER__PERMISSION__SETUP,
    Constants.USER__PERMISSION__WRITE,
  );
  return (
    <>
      <section
        role="dialog"
        tabIndex="-1"
        aria-labelledby="modal-heading-01"
        aria-modal="true"
        aria-describedby="modal-content-id-1"
        className={adminUserModalClassName}
        id="admin-user-modal"
      >
        <div className="slds-modal__container" style={{ fontSize: '12px' }}>
          <header className="slds-modal__header">
            <h2
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Create Admin User
            </h2>
          </header>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
            style={{ minHeight: '10rem' }}
          >
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-user-name">
                Username
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-user-name"
                    placeholder="Enter the username"
                    className="slds-input"
                    onChange={event => setUserName(event.target.value)}
                    value={username}
                    disabled={eventType === Constants.EVENT__TYPE__UPDATE}
                  />
                </div>
              </label>
            </div>
            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-password">
                Password
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-password"
                    placeholder="Enter the password"
                    className="slds-input"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                    disabled={eventType === Constants.EVENT__TYPE__UPDATE}
                  />
                </div>
              </label>
            </div>

            <h2>Permissions</h2>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-default-setting">
                Permission default settings (choose a default setting here for
                easy configuration of the permissions based on the needs of the user)
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => changePermissionDefaultSetting(event.target.value)}
                      id="form-element-permission-default-setting"
                      value={permissionDefaultSetting}
                    >
                      <option value="" defaultValue>Please select</option>
                      <option
                        key={Constants.PERMISSION_DEFAULT__AE}
                        value={Constants.PERMISSION_DEFAULT__AE}
                      >
                        Account Executive
                      </option>
                      <option
                        key={Constants.PERMISSION_DEFAULT__CSM}
                        value={Constants.PERMISSION_DEFAULT__CSM}
                      >
                        Customer Success Manager
                      </option>
                      <option
                        key={Constants.PERMISSION_DEFAULT__PRODUCT}
                        value={Constants.PERMISSION_DEFAULT__PRODUCT}
                      >
                        Product Team Member
                      </option>
                      <option
                        key={Constants.PERMISSION_DEFAULT__MARKETING}
                        value={Constants.PERMISSION_DEFAULT__MARKETING}
                      >
                        Marketing
                      </option>
                      <option
                        key={Constants.PERMISSION_DEFAULT__ENGINEER}
                        value={Constants.PERMISSION_DEFAULT__ENGINEER}
                      >
                        Engineer
                      </option>
                      <option
                        key={Constants.PERMISSION_DEFAULT__FINANCE}
                        value={Constants.PERMISSION_DEFAULT__FINANCE}
                      >
                        Finance
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <br />
            <br />

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-developer">
                Developer (testing, change logs, selection run logs, features, settings)
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setDeveloperPermission(event.target.value)}
                      id="form-element-permission-developer"
                      value={developerPermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                      <option key={Constants.USER__PERMISSION__WRITE} value={Constants.USER__PERMISSION__WRITE}>
                        Write
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-finance">
                Finance (create ExactOnline invoices, stamp deals)
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setFinancePermission(event.target.value)}
                      id="form-element-permission-finance"
                      value={financePermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                      <option key={Constants.USER__PERMISSION__WRITE} value={Constants.USER__PERMISSION__WRITE}>
                        Write
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-csm">
                CSM (manage organisations, users, (user) notifications, change / selection run logs,
                install demo data, Asana tasks)
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setCsmPermission(event.target.value)}
                      id="form-element-permission-csm"
                      value={csmPermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                      <option key={Constants.USER__PERMISSION__WRITE} value={Constants.USER__PERMISSION__WRITE}>
                        Write
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-reports">
                Product
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setProductPermission(event.target.value)}
                      id="form-element-permission-reports"
                      value={productPermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                      <option key={Constants.USER__PERMISSION__WRITE} value={Constants.USER__PERMISSION__WRITE}>
                        Write
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-reports">
                Marketing
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setMarketingPermission(event.target.value)}
                      id="form-element-permission-reports"
                      value={marketingPermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                      <option key={Constants.USER__PERMISSION__WRITE} value={Constants.USER__PERMISSION__WRITE}>
                        Write
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-reports">
                Reports (usage dashboards)
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setReportsPermission(event.target.value)}
                      id="form-element-permission-reports"
                      value={reportsPermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-permission-setup">
                Setup (manage Admin App users)
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      onChange={event => setSetupPermission(event.target.value)}
                      id="form-element-permission-setup"
                      value={setupPermission}
                    >
                      <option key="" value="">
                        No access
                      </option>
                      <option key={Constants.USER__PERMISSION__READ} value={Constants.USER__PERMISSION__READ}>
                        Read Only
                      </option>
                      <option key={Constants.USER__PERMISSION__WRITE} value={Constants.USER__PERMISSION__WRITE}>
                        Write
                      </option>
                    </select>
                  </div>
                </div>
              </label>
            </div>

            <h2>Customer Success Manager</h2>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-asana-user-id">
                Asana User ID
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-asana-user-id"
                    placeholder="Name"
                    className="slds-input"
                    onChange={event => setAsanaUserId(event.target.value)}
                    value={asanaUserId}
                  />
                </div>
              </label>
            </div>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-asana-project-id">
                Asana Project ID
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-asana-project-id"
                    name="asanaProjectId"
                    placeholder="Name"
                    className="slds-input"
                    onChange={event => setAsanaProjectId(event.target.value)}
                    value={asanaProjectId}
                  />
                </div>
              </label>
            </div>

            <h2>Finance</h2>

            <div className="slds-form-element">
              <label className="slds-form-element__label" htmlFor="form-element-exact-online-username">
                ExactOnline UserName
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="form-element-exact-online-username"
                    name="exactOnlineUserName"
                    placeholder="ExactOnline UserName"
                    className="slds-input"
                    onChange={event => setExactOnlineUserName(event.target.value)}
                    value={exactOnlineUserName}
                  />
                </div>
              </label>
            </div>

          </div>
          <footer className="slds-modal__footer">
            <button
              type="button"
              className="slds-button slds-button_neutral"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={!userHasEditRights}
              className="slds-button slds-button_brand"
            >
              Save
            </button>
          </footer>
        </div>
      </section>
      <div className={adminUserBackdropClassName} />
    </>
  );
};

AdminUserModal.propTypes = {
  /* Open the modal */
  isOpen: PropTypes.bool.isRequired,
  /** Label for username */
  username: PropTypes.string.isRequired,
  /** set state for username */
  setUserName: PropTypes.func.isRequired,
  /** label for password */
  password: PropTypes.string.isRequired,
  /** set state for password */
  setPassword: PropTypes.func.isRequired,
  /** label for password */
  asanaUserId: PropTypes.string,
  /** set state for password */
  setAsanaUserId: PropTypes.func.isRequired,
  /** label for asanaProjectId */
  asanaProjectId: PropTypes.string,
  /** set state for password */
  setAsanaProjectId: PropTypes.func.isRequired,
  /** label for exactOnlineUserName */
  exactOnlineUserName: PropTypes.string,
  /** set state for exactOnlineUserName */
  setExactOnlineUserName: PropTypes.func.isRequired,
  /** set state for permissionDefaultSetting */
  setPermissionDefaultSetting: PropTypes.func.isRequired,
  /** Permission default setting */
  permissionDefaultSetting: PropTypes.string,
  /** handles the changing of the developer permission */
  setDeveloperPermission: PropTypes.func.isRequired,
  /** permission: developer */
  developerPermission: PropTypes.string,
  /** handles the changing of the finance permission */
  setFinancePermission: PropTypes.func.isRequired,
  /** permission: finance */
  financePermission: PropTypes.string,
  /** handles the changing of the AE permission */
  setReportsPermission: PropTypes.func.isRequired,
  /** permission: AE */
  reportsPermission: PropTypes.string,
  /** handles the changing of the csm permission */
  setCsmPermission: PropTypes.func.isRequired,
  /** permission: csm */
  csmPermission: PropTypes.string,
  /** handles the changing of the product permission */
  setProductPermission: PropTypes.func.isRequired,
  /** permission: product */
  productPermission: PropTypes.string,
  /** handles the changing of the marketing permission */
  setMarketingPermission: PropTypes.func.isRequired,
  /** permission: marketing */
  marketingPermission: PropTypes.string,
  /** handles the changing of the setup permission */
  setSetupPermission: PropTypes.func.isRequired,
  /** permission: setup */
  setupPermission: PropTypes.string,
  /** informs which modal mode we are in - edition or new */
  eventType: PropTypes.string.isRequired,
  /** handles the submit button */
  handleSave: PropTypes.func.isRequired,
  /** handles the cancel button */
  handleCancel: PropTypes.func.isRequired,
};

export default AdminUserModal;
