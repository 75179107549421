import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import Util from '../../util';

/**
 * Check if user is authenticated
 * @returns {boolean} True if user is authenticated, false otherwise
 */
const isAuth = () => {
  const cookieName = Util.getCookieName();
  return Cookies.get(cookieName);
};

/* eslint-disable no-shadow, react/jsx-props-no-spreading */
const ProtectedRoute = ({
  component: Component, notAuthOnly, redirectTo, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (notAuthOnly) {
        return isAuth() ?
          <Redirect to={redirectTo} /> :
          <Component {...props} />;
      }
      return isAuth() ?
        <Component {...props} /> :
        <Redirect to={redirectTo} />;
    }}
  />
);
/* eslint-enable no-shadow, react/jsx-props-no-spreading */

ProtectedRoute.propTypes = {
  /**
   * Component to render
   */
  component: PropTypes.func.isRequired,
  /**
   * Whether or not the component should be render ONLY if the user is not auth
   */
  notAuthOnly: PropTypes.bool,
  /**
   * Where to redirect:
   *  - If `notAuthOnly` is TRUE, redirect ON SUCCESS
   *  - else, redirect ON FAIL
   */
  redirectTo: PropTypes.string.isRequired,
};

export default ProtectedRoute;
