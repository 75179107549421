import axios from 'axios';
import Constants from '../constants/constants';

const apiUrl = process.env.REACT_APP_API_URL;
const adminPass = process.env.REACT_APP_ADMIN_PASS;

const UsersAPI = {
  /**
   * Retrieves all users
   * @param {string} product - The product identifier (segment/engage)
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `users` property - the retrieved users - and `success`
   */
  getUsers: async (product, cancelToken) => {
    let res;
    if (product === Constants.DESELECT_SEGMENT) {
      res = await axios.get(
        `${apiUrl}/admin/users/segment`, {
          cancelToken,
          withCredentials: true,
        },
      );
    } else if (product === Constants.DESELECT_ENGAGE) {
      res = await axios.get(
        `${apiUrl}/admin/users/engage`, {
          cancelToken,
          withCredentials: true,
        },
      );
    }
    return res.data;
  },

  /**
   * Retrieves all users for one organisation
   * @param {object} orgId - Id of the organisation for which we request the users
   * @param {object} cancelToken - The cancel token from Axios
   * @returns {object} An object with `users` property - the retrieved users - and `success`
   */
  getUsersForOrg: async (orgId, cancelToken) => {
    const res = await axios.get(`${apiUrl}/admin/users/segment/${orgId}`, {
      cancelToken,
      withCredentials: true,
    });
    return res.data;
  },

  /**
   * Updates a user
   * @param {string} product - The product identifier (segment/engage)
   * @param {object} cancelToken - The cancel token from Axios
   * @param {string} id - ID of the user to edit
   * @param {boolean} isActive - Should this user be active
   * @param {boolean} isAdmin - Should this user be an admin
   * @return {object} An object with `result` property - always true - and `success`
   */
  updateUser: async (
    product,
    cancelToken,
    id,
    {
      isActive,
      isAdmin,
      zendeskID,
    },
  ) => {
    const productInUrl = product === Constants.DESELECT_SEGMENT ? 'segment' : 'engage';
    const res = await axios.put(
      `${apiUrl}/admin/users/${productInUrl}/${id}`, {
        pw: adminPass,
        isActive,
        isAdmin,
        zendeskID,
      }, {
        cancelToken,
        withCredentials: true,
      },
    );

    return res.data;
  },
};

export default UsersAPI;
