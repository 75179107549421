import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const ResultsContext = createContext();

const ResultsProvider = ({ children }) => {
  const [results, setResults] = useState({
    segmentOrganisationsData: [],
    engageOrganisationsData: [],
    segmentUsersData: [],
    engageUsersData: [],
    dashboardData: [],
    engagementDashboardData: [],
    essentialsDashboardData: [],
    unmappedTicketsData: [],
    adoptionDashboardData: [],
    changeLogsData: [],
    notificationsData: [],
    alertsData: [],
    integrationsData: [],
    relationsData: [],
    userNotificationsData: [],
    certificatesData: [],
    selectionRunLogsData: [],
    filteredLogs: [],
    modules: [],
    featureAdvertsData: [],
    guidanceTips: [],
  });

  return (
    <ResultsContext.Provider value={[results, setResults]}>
      {children}
    </ResultsContext.Provider>
  );
};

ResultsProvider.propTypes = {
  /**
   * Children nodes for this component
   */
  children: PropTypes.node,
};

export { ResultsProvider };
